const LOAD = 'app/topics/LOAD'
const LOAD_SUCCESS = 'app/topics/LOAD_SUCCESS'
const LOAD_ERROR = 'app/topics/LOAD_ERROR'

const load = (id) => ({ type: LOAD, id })
const loadSuccess = (topics) => ({ type: LOAD_SUCCESS, topics })
const loadError = (errors) => ({ type: LOAD_ERROR, errors })

export const TopicsActions = {
  load,
  loadSuccess,
  loadError,
}

export const TopicsActionTypes = {
  LOAD,
  LOAD_SUCCESS,
  LOAD_ERROR,
}
