import React from 'react'
import PropTypes from 'prop-types'

import { learningFormatTypes } from './constants'

const LearningFormatIcon = function ({ learningFormat, location, className }) {
  if (learningFormat === learningFormatTypes.inPerson && location) {
    return (
      <>
        <div className={`${className} indicator__icon--location`} />
        <div className="indicator__icon-text">
          {location}
        </div>
      </>
    )
  }
  return (
    <>
      <div className={`${className} indicator__icon--${learningFormat}`} />
      <div className="indicator__icon-text">{learningFormat}</div>
    </>
  )
}

LearningFormatIcon.propTypes = {
  learningFormat: PropTypes.string,
  location: PropTypes.string,
  className: PropTypes.string.isRequired,
}

export default LearningFormatIcon
