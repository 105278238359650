import React from 'react'
import ReactMarkdown from 'react-markdown'
import PropTypes from 'prop-types'

import classNames from 'classnames'
import { isEmpty } from 'lodash'
import CollectionCardPortrait from './CollectionCardPortrait'
import CollectionCardResourceCount from './CollectionCardResourceCount'
import { OBJECT_TYPES } from '../shared'
import routes from '../../services/routes'

const CareerCollectionCard = function ({
  collection, bypassRedux, loggedIn, className,
}) {
  if (isEmpty(collection)) return null
  const resourceCount = collection.collection_sections.map((section) => section.learning_objects.length)
    .reduce((a, b) => a + b, 0)

  return (
    <a
      className={classNames('collection-card', `collection-card--${collection.theme_variant}`, className)}
      href={routes.careerCollections.show(collection.id)}
    >
      <CollectionCardPortrait
        collection={collection}
        type={OBJECT_TYPES.careerCollection}
        bypassRedux={bypassRedux}
        loggedIn={loggedIn}
      />
      <div className="collection-card__text-section">
        {/* <h4 className="heading-4 heading-4--gray-1 collection-card__title"> */}
        <h4 className="heading-4 heading-4--gray-2 collection-card__heading">
          <ReactMarkdown children={collection.title} />
        </h4>
        <div className="collection-card__description">
          <div className="collection-card__description-text--career">
            <div className="collection-card__description-text-main">
              <h3 className="collection-card__header">
                Career Collection
              </h3>
              <ReactMarkdown
                className="body-copy-2 body-copy-2--gray-1 collection-card__industry"
                children={collection.industry}
              />
            </div>

            <CollectionCardResourceCount resourceCount={resourceCount} />
          </div>

        </div>
      </div>
      <div className="collection-card__bottom-banner collection-card__bottom-banner--career" />
    </a>
  )
}

CareerCollectionCard.propTypes = {
  collection: PropTypes.object,
  bypassRedux: PropTypes.bool,
  loggedIn: PropTypes.bool,
  className: PropTypes.string,
}

export default CareerCollectionCard
