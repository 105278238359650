import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { getSetting } from '../../services/hubApi';
import SearchFacetSelect from './SearchFacetSelect';
import SearchZipcode from './SearchZipcode';

// const shouldLog = false;

const learningFormatOptions = [
  // { label: 'Any', value: 'any' },
  { label: 'Online', value: 'online' },
  { label: 'In-person', value: 'in-person' }, // app expects dashes even though original SQL data has underscores
  { label: 'Video', value: 'video' },
  { label: 'Podcast', value: 'podcast' },
  { label: 'Sms-text', value: 'sms-text' }, // app expects dashes even though original SQL data has underscores
  { label: 'Book', value: 'book' },
  { label: 'Game', value: 'game' },
]

const sessionLengthOptions = [
  // { label: 'Any', value: 'any' },
  { label: 'Short', value: 'short' },
  { label: 'Long', value: 'long' },
]

const instructionStyleOptions = [
  // { label: 'Any', value: 'any' },
  { label: 'Self-paced', value: 'self-paced' },
  { label: 'Instructor-led', value: 'live-instruction' },
]

// const creditOptions = [
//   // { label: 'Any', value: 'any' },
//   { label: 'College', value: 'college' },
//   { label: 'Industry certificate', value: 'certificate' },
// ]

const ageRangeOptions = [
  // { label: 'Any', value: 'any' },
  { label: 'Youth', value: 'youth' },
  { label: 'Adult', value: 'adult' },
  { label: 'Senior', value: 'senior' },
]

const languageOptions = [
  // { label: 'Any', value: 'any' },
  { label: 'English', value: 'English' },
  { label: 'Spanish', value: 'Spanish' },
]

const SearchFacets = (props) => { // eslint-disable-line react/function-component-definition
  const {
    topicOptions,
    providerOptions,
    visible,
    facetData,
    zipcodeData,
    onChange,
    onZipcodeDataChange,
    onReset,
    onRefresh,
  } = props;
  // if (shouldLog) console.log('SearchFacetSelect.handleChange() facetData: ', facetData);

  const [shouldShowSearchZipcode, setShouldShowSearchZipcode] = useState(false);

  // dynamically get setting 'search.zipcode_search' to determine if we should show the Zipcode Search panel
  useEffect(() => {
    const asyncSafe = async () => {
      try {
        const setting = await getSetting('search.zipcode_search');
        setShouldShowSearchZipcode(setting === 'enabled');
      } catch (err) {
        setShouldShowSearchZipcode(false);
      }
    };
    asyncSafe();
  }, []);

  /**
   * Pass change action up component tree
   *
   * @param {*} action Example shape for 'topics': { name: 'topic', value: [{ label: 'Design', value: 'Design' }]}
   */
  const handleChange = (action) => {
    // if (shouldLog) console.log('SearchFacets.handleChange() action: ', action);
    if (onChange) onChange(action)
  }

  const handleZipcodeDataChange = (action) => {
    // if (shouldLog) console.log('SearchFacets.handleZipcodeDataChange() action: ', action);
    if (onZipcodeDataChange) onZipcodeDataChange(action);
  };

  const handleReset = () => {
    if (onReset) onReset()
  }

  const handleAccessibleReset = (e) => {
    // Accessibility function that resets the search filters
    // when 'space' or 'return' keys are pressed
    if (e.which === 13 || e.which === 32) {
      e.preventDefault()
      handleReset()
    }
  }

  const handleZipcodeRefresh = () => {
    if (onRefresh) onRefresh();
  };

  return (
    <div className="search-facets" style={{ display: visible ? 'block' : 'none' }}>

      <div className="search-layout">
        <SearchFacetSelect
          name="topic"
          label="Topic"
          placeholder="Select filters..."
          options={topicOptions}
          selectedOptions={facetData?.topic || null}
          onChange={handleChange}
        />
        <div className="gutter" />
        <SearchFacetSelect
          name="learning_format"
          label="Learning format"
          placeholder="Select filters..."
          options={learningFormatOptions}
          selectedOptions={facetData?.learning_format || null}
          onChange={handleChange}
        />
      </div>

      <div className="search-layout">
        <SearchFacetSelect
          name="session_length"
          label="Session length"
          placeholder="Select filters..."
          options={sessionLengthOptions}
          selectedOptions={facetData?.session_length || null}
          onChange={handleChange}
        />
        <div className="gutter" />
        <SearchFacetSelect
          name="self_paced"
          label="Instruction style"
          placeholder="Select filters..."
          options={instructionStyleOptions}
          selectedOptions={facetData?.self_paced || null}
          onChange={handleChange}
        />
      </div>

      <div className="search-layout">
        <SearchFacetSelect
          name="age_ranges"
          label="Age ranges"
          placeholder="Select filters..."
          options={ageRangeOptions}
          selectedOptions={facetData?.age_ranges || null}
          onChange={handleChange}
        />
        <div className="gutter" />
        <SearchFacetSelect
          name="language"
          label="Language"
          placeholder="Select filters..."
          options={languageOptions}
          selectedOptions={facetData?.language || null}
          onChange={handleChange}
        />
      </div>

      <div className="search-layout">
        <SearchFacetSelect
          name="provider.name"
          label="Provider"
          placeholder="Select filters..."
          options={providerOptions}
          selectedOptions={(facetData && facetData['provider.name']) || null}
          onChange={handleChange}
        />
        <div className="gutter" />
        {/* <SearchFacetSelect
          name="credit"
          label="Credit"
          placeholder="Select filters..."
          options={creditOptions}
          selectedOptions={facetData?.credit || null}
          onChange={handleChange}
        /> */}
      </div>

      <div
        className="search-layout"
        style={{
          display: shouldShowSearchZipcode ? 'block' : 'none',
          marginTop: '25px',
          marginBottom: '20px',
        }}
      >
        <SearchZipcode
          zipcodeData={zipcodeData}
          onChange={handleZipcodeDataChange}
          onRefresh={handleZipcodeRefresh}
        />
      </div>

      <div className="reset-button-row">
        <div className="row-pad" />
        <div className="reset-button-wrapper">
          <div
            role="button"
            tabIndex={0}
            onClick={handleReset}
            onKeyPress={handleAccessibleReset}
            className="button button--solid-bendable-logo-blue button--short reset__button"
          >
            <span className="button__text button__text--short">Clear all filters</span>
          </div>
        </div>
      </div>

    </div>
  )
}

SearchFacets.propTypes = {
  topicOptions: PropTypes.array.isRequired,
  providerOptions: PropTypes.array,
  visible: PropTypes.bool,
  facetData: PropTypes.object,
  zipcodeData: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onZipcodeDataChange: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
};

export default SearchFacets
