import {
  take, put, call,
} from 'redux-saga/effects'

import { HomepageSectionsActions, HomepageSectionsActionTypes } from '../../../actions'
import api from '../../../services/api'

export default function* loadHomepageSections() {
  while (true) {
    yield take(HomepageSectionsActionTypes.LOAD)
    try {
      const response = yield call(
        api.homepage.homepageSections.index,
      )

      yield put(HomepageSectionsActions.loadSuccess(response.data))
    } catch (error) {
      yield put(HomepageSectionsActions.loadError(error.response.data.errors))
    }
  }
}
