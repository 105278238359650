const LOAD = 'app/featuredBoardGroups/LOAD'
const LOAD_SUCCESS = 'app/featuredBoardGroups/LOAD_SUCCESS'
const LOAD_ERROR = 'app/featuredBoardGroups/LOAD_ERROR'

const load = () => ({ type: LOAD })
const loadSuccess = (featuredBoardGroups) => ({ type: LOAD_SUCCESS, featuredBoardGroups })
const loadError = (errors) => ({ type: LOAD_ERROR, errors })

export const FeaturedBoardGroupsActions = {
  load,
  loadSuccess,
  loadError,
}

export const FeaturedBoardGroupsActionTypes = {
  LOAD,
  LOAD_SUCCESS,
  LOAD_ERROR,
}
