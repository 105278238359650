import {
  takeEvery, put, call,
} from 'redux-saga/effects'

import { LearningObjectsActions, LearningObjectsActionTypes } from '../../../actions'
import api from '../../../services/api'

function* load(action) {
  try {
    const response = yield call(
      api.categories.learningObjects.index,
      action.parentId,
      action.page,
    )
    yield put(
      LearningObjectsActions.loadSuccess(
        response.data.learning_objects,
        response.data.final_page_reached,
      ),
    )
  } catch (error) {
    yield put(LearningObjectsActions.loadError(error.response.data.errors))
  }
}

export default function* loadLearningObjects() {
  yield takeEvery(LearningObjectsActionTypes.LOAD, load)
}
