import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'

import {
  trackExploreEvent,
  trackLearningObjectReferralPage,
  usePageTitle,
} from '../shared'
import { SearchBar } from '../Search'
import { CategoryButton } from '../Category'
import { ExploreSectionsActions, BoardsActions } from '../../actions'
import Boards from './Boards'

const ExplorePage = function () {
  const dispatch = useDispatch()
  const exploreSections = useSelector((state) => state.app.exploreSections.items)
  const boards = useSelector((state) => state.app.boards.items)

  useEffect(() => {
    trackExploreEvent()
    trackLearningObjectReferralPage('explore')
    dispatch(ExploreSectionsActions.load())
  }, [])

  useEffect(() => {
    dispatch(BoardsActions.load())
  }, [dispatch, BoardsActions.load])

  usePageTitle('Discover')

  return (
    <div className="explore">
      <div className="explore__content" key="meow">
        <SearchBar navigateOnly />
        {
          exploreSections.map((exploreSection) => (
            <React.Fragment key={exploreSection.id}>
              <div className="explore__explainer-text">
                <h2 className="heading-2">
                  { exploreSection.headline }
                </h2>
              </div>
              <div className="explore__grid">
                {
                  exploreSection.categories.map((category) => (
                    <div key={category.id} className="explore__category-button">
                      <CategoryButton category={category} />
                    </div>
                  ))
                }
              </div>
            </React.Fragment>
          ))
        }
        {!isEmpty(boards) && (
          <>
            <div className="explore__explainer-text">
              <h2 className="heading-2">
                Browse Bendable Boards
              </h2>
            </div>
            <Boards boards={boards} />
          </>
        )}
      </div>
    </div>
  )
}

export default ExplorePage
