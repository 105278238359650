export const learningFormatTypes = {
  inPerson: 'in-person',
  online: 'online',
  video: 'video',
  podcast: 'podcast',
  sms: 'sms-text',
  book: 'book',
  game: 'game',
}

export const learningObjectTypes = {
  resource: 'Resource',
  community_partner: 'Community Learning Partner',
}
