import React from 'react'
import { useNavigate } from 'react-router-dom'

const Back = function () {
  const navigate = useNavigate()

  return (
    <div className="navbar navbar--back-button">
      <div className="navbar__bar">
        <nav className="navbar__content">
          <div className="navbar__item navbar__item--back-button">
            <button
              onClick={() => navigate(-1)}
              aria-label="Back"
              className="back-button"
              type="button"
            >
              <div className="back-button__icon" />
              <div className="back-button__text">
                <div className="button-text button-text--in-nav">
                  Back
                </div>
              </div>
            </button>
          </div>
        </nav>
      </div>
    </div>
  )
}

export default Back
