import React, {
  useCallback, useRef, useEffect,
} from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import GridCombined from './GridCombined'
import { LOADING_STATUSES } from '../shared'

const LoadingGrid = function ({
  objectsState,
  objects,
  load,
  incrementPage,
  firstPage,
}) {
  const dispatch = useDispatch()
  const learner = useSelector((state) => state.app.learner)
  const loader = useRef(null)

  const callNext = useCallback(() => {
    dispatch(load(objectsState.page))
  }, [objectsState.page])

  const handleObserver = useCallback((entries) => {
    const target = entries[0]
    if (target.isIntersecting && objectsState.status !== LOADING_STATUSES.loading) {
      dispatch(incrementPage())
    }
  }, [objectsState.status])

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: '0px',
      threshold: 0.15,
    }
    const observer = new IntersectionObserver(handleObserver, option)

    if (loader.current) {
      observer.observe(loader.current)
    }
  }, [handleObserver])

  useEffect(() => {
    if (objectsState.page > firstPage && !objectsState.finalPageReached) {
      callNext()
    }
  }, [callNext, objectsState.page])

  return (
    <>
      <GridCombined
        objects={objects}
        learner={learner}
      />
      { objectsState.status === LOADING_STATUSES.notLoaded
        && (
          <div className="infinite-scroller" />
        )}
      { objectsState.status === LOADING_STATUSES.loaded
        && <div className="infinite-scroller infinite-scroller--padded" ref={loader} />}
      { objectsState.status === LOADING_STATUSES.loading
        && (
          <div className="infinite-scroller">
            <div className="infinite-scroller__loader" />
          </div>
        )}
    </>
  )
}

LoadingGrid.propTypes = {
  objectsState: PropTypes.shape({
    page: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
    finalPageReached: PropTypes.bool.isRequired,
  }),
  objects: PropTypes.array.isRequired,
  load: PropTypes.func.isRequired,
  incrementPage: PropTypes.func.isRequired,
  firstPage: PropTypes.number.isRequired,
}

export default LoadingGrid
