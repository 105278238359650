import {
  take, put, call,
} from 'redux-saga/effects'

import { FeaturedBoardGroupsActions, FeaturedBoardGroupsActionTypes } from '../../../actions'
import api from '../../../services/api'

export default function* loadFeaturedBoardGroups() {
  while (true) {
    yield take(FeaturedBoardGroupsActionTypes.LOAD)

    try {
      const response = yield call(
        api.homepage.featuredBoardGroups.show,
      )

      yield put(FeaturedBoardGroupsActions.loadSuccess(response.data))
    } catch (error) {
      yield put(FeaturedBoardGroupsActions.loadError(error.response.data.errors))
    }
  }
}
