$(() => {
  const confirmEmail = $('.js-my-stuff-profile-email-confirm-container')
  const form = $('.js-edit-profile')
  const profile = $('.my-stuff__profile')

  confirmEmail.hide()
  form.ready(() => {
    const initialEmail = $('#user_email').val()
    $('.js-my-profile-submit-button-edit').on('click', (e) => {
      e.preventDefault()
      if ($('#user_email').val() === initialEmail) {
        form.submit()
      } else {
        confirmEmail.show()
        profile.hide()
        $('.js-my-stuff-profile-email-cancel-button').on('click', () => {
          confirmEmail.hide()
          profile.show()
          return false
        })
        $('.js-my-stuff-profile-email-continue-button').on('click', () => {
          form.on('submit', () => {
            profile.hide()
            confirmEmail.hide()
          })
          form.submit()
        })
      }
    })
  })
})
