const { constants } = require('../constants')
const { api } = require('../helpers')

$(() => {
  function extractLearningObjectLocation($target) {
    const { pathname, search } = window.location
    if ($target.attr('type') === 'LearningObject') {
      return `${pathname}${search}#${$target.data('anchorTag')}`
    }

    return null
  }

  function triggerFlashNotification($target) {
    const notification = $target.data('flashNotification')
    const targetedNotification = $target.data('targetedNotification')
    const learningObjectLocation = extractLearningObjectLocation($target)
    const url = constants.routes.apiFlashMessagesPath()
    const data = {
      notification,
      targeted_notification: targetedNotification,
      learning_object_location: learningObjectLocation,
    }
    api.post(url, data)
  }

  $(document).on('click', '.js-trigger-flash-notification', (event) => {
    event.preventDefault()
    const $target = $(event.currentTarget)

    if ($target.data('flashNotification')) {
      triggerFlashNotification($target)
    } else {
      /* eslint-disable no-console */
      console.error(
        'attribute data-notification must be provided to render a flash message',
      )
      /* eslint-enable no-console */
    }
  })
})
