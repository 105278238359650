import React from 'react'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import FavoriteExampleCard from 'images/favorite-example-card.png'

import classNames from 'classnames'
import GenericCard from './GenericCard'

const MyFavorites = function ({ favoriteResources, learner }) {
  const empty = isEmpty(favoriteResources.items)
  return (
    <div className="my_favorites__background">
      <div className="my_favorites__container">
        <div className="my_favorites__instructions">
          <h1 className="my_favorites__header">
            My favorites
          </h1>
          <h2 className={classNames('my_favorites__subheader', { 'my_favorites__subheader--empty': empty })}>
            When you click the heart on a learning resource,
            it will show up here. This is a great way to keep
            track of the learning you love.
          </h2>
          {!empty && (
            <div className="my_favorites__instructions-cta">
              <a
                className="my_favorites__button"
                href="/my_stuff/favorites"
              >
                Visit my favorites
              </a>
            </div>
          )}
        </div>
        {empty
          ? (
            <div className="my_favorites__example-card">
              <img
                src={FavoriteExampleCard}
                alt="Example learning resource card with cursor hovering over heart button"
              />
            </div>
          )
          : (
            <>

              <div className="my_favorites__cards">
                {favoriteResources.items.map((resource, index) => (
                  <div className="my_favorites__card" key={`${resource.type}-${resource.id}`}>
                    <GenericCard index={index} loggedIn={!isEmpty(learner)} resource={resource} />
                  </div>
                ))}
              </div>
              <div className="my_favorites__cta">
                <a
                  className="my_favorites__button"
                  href="/my_stuff/favorites"
                >
                  Visit my favorites
                </a>
              </div>
            </>
          )}
      </div>
    </div>
  )
}

MyFavorites.propTypes = {
  favoriteResources: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.string,
      id: PropTypes.number,
    })),
  }),
  learner: PropTypes.object,
}

export default MyFavorites
