const LOAD = 'local/learningObject/LOAD'
const LOAD_SUCCESS = 'local/learningObject/LOAD_SUCCESS'
const LOAD_ERROR = 'local/learningObject/LOAD_ERROR'
const SET_COLOR = 'local/learningObject/SET_COLOR'

const load = (id) => ({ type: LOAD, id })
const loadSuccess = (learningObject) => ({ type: LOAD_SUCCESS, learningObject })
const loadError = (errors) => ({ type: LOAD_ERROR, errors })
const setColor = (color) => ({ type: SET_COLOR, color })

export const LearningObjectActions = {
  load,
  loadSuccess,
  loadError,
  setColor,
}

export const LearningObjectActionTypes = {
  LOAD,
  LOAD_SUCCESS,
  LOAD_ERROR,
  SET_COLOR,
}
