import React from 'react'
import { useSelector } from 'react-redux'
import classnames from 'classnames'
import PropTypes from 'prop-types';

import { isEmpty } from 'lodash'
import Logos from '../../LearningObjects/Logos'

import {
  Favorite,
  selectFavorite,
  NO_OF_LO_IMAGES,
  OBJECT_TYPES,
  USER_STATUSES,
  FLASH_NOTIFICATIONS,
} from '../../shared'

const CollectionLearningObjectCard = function ({ learningObject, index, lastInSection }) {
  if (isEmpty(learningObject)) return null

  const myFavorite = useSelector(selectFavorite(learningObject, OBJECT_TYPES.learningObject))
  const linkId = `learning_object_${learningObject.id}`
  const imageNumber = index % NO_OF_LO_IMAGES
  const learner = useSelector((state) => state.app.learner)
  const prettyLearningFormat = learningObject.learning_format.replace('_', ' ')

  const flashNotification = () => {
    if (learningObject.flash_notification) { return learningObject.flash_notification }

    if (!learningObject.prepaid_seats) { return false }

    if (isEmpty(learner)) {
      return FLASH_NOTIFICATIONS.loginForPaidResource
    }

    if (learner?.status === USER_STATUSES.expired) {
      return FLASH_NOTIFICATIONS.libraryCardRequiredForPaidResource
    }

    return null
  }

  return (
    <div
      className="collection-learning-object-card"
      data-learning-object-id={learningObject.id}
      ind={linkId}
    >
      <a
        to={`/learning_objects/${learningObject.id}`}
        className={classnames(
          'learning-object-card__link',
          { 'js-trigger-flash-notification': flashNotification() },
        )}
        title={`${learningObject.title}`}
        type="LearningObject"
        data-targeted-notification={linkId}
        data-anchor-tag={linkId}
        data-flash-notification={flashNotification()}
        href={`/learning_objects/${learningObject.id}?bg_img=${imageNumber}`}
      >
        <Favorite
          favorite={myFavorite}
          object={learningObject}
          objectType={OBJECT_TYPES.learningObject}
          loggedIn={!isEmpty(learner)}
        />
        <div className="collection-learning-object__image-container">
          <div className={`collection-learning-object__image collection-learning-object__image--bg-image-${index}`} />
        </div>
        {
                    learningObject.location && (
                    <div className="collection-learning-object__location-tag">
                      <div className="collection-learning-object__location-tag-icon" />
                      <div className="collection-learning-object__location-tag-text">
                        <div className="tags tags--pop">
                          {learningObject.location}
                        </div>
                      </div>
                    </div>
                    )
                }
        <div className="collection-learning-object__text">
          <h3 className="collection-learning-object__title heading-3 heading-3--decoration">
            {learningObject.title}
          </h3>
        </div>
        <div className="collection-learning-object__text">
          <p className="body-copy-1 body-copy-1--gray-5">
            {learningObject.bendable_description}
          </p>
        </div>
        <div className="learning-object-card__footer-container">
          <div className="learning-object-card__footer-row">
            <p className="learning-object-card__learning-format tags">
              {prettyLearningFormat}
            </p>
          </div>
          <div className="learning-object-card__footer-row">
            <Logos
              learningObject={learningObject}
              imageClassName="provider-logo__image"
            />
          </div>
        </div>
        { !lastInSection && (
        <div className="career-skill__divider">
          <div className="divider" />
        </div>
        )}
      </a>
    </div>
  )
}

CollectionLearningObjectCard.propTypes = {
  learningObject: PropTypes.object,
  index: PropTypes.number,
  lastInSection: PropTypes.bool,
};

export default CollectionLearningObjectCard
