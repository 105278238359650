import React from 'react'
import PropTypes from 'prop-types'

import PartOfCollection from './PartOfCollection'

const Logos = function ({ learningObject, imageClassName }) {
  if (learningObject.author_recommended_in_a_collection) {
    return <PartOfCollection collection={learningObject.collection} />
  }
  if (learningObject.provider) {
    if (learningObject.provider.logo) {
      return (
        <div className="provider-logo">
          <img
            className={imageClassName}
            src={learningObject.provider.logo}
            alt={learningObject.provider.name}
          />
        </div>
      )
    }
    return (
      <div className="provider-logo__text">
        {learningObject.provider.name}
      </div>
    )
  }
  return null
}

Logos.propTypes = {
  learningObject: PropTypes.object.isRequired,
  imageClassName: PropTypes.string,
}

export default Logos
