/* eslint-disable object-shorthand */
/* eslint-disable no-param-reassign */
/* eslint-disable arrow-body-style */
/**
 * Hub API Service
 */

import axios from 'axios';
import {
  forOwn,
  isNumber,
  isString,
  get,
} from 'lodash';

// const shouldLog = false;
// const verboseLogging = false;
const isTestEnv = (process.env.NODE_ENV === 'test');
// if (shouldLog) console.log('hubApi railsEnv: ', railsEnv);
const apiBaseUrl = process.env.HUB_API_ENDPOINT_BASE_URL;
// if (shouldLog) console.log('hubApi apiBaseUrl: ', apiBaseUrl);
const hubApiPublicKey = process.env.HUB_API_PUBLIC_KEY;
// if (shouldLog) console.log('hubApi hubApiPublicKey: ', hubApiPublicKey);
const bendableInstance = process.env.BENDABLE_INSTANCE;
// if (shouldLog) console.log('hubApi bendableInstance: ', bendableInstance);

// Expand this data if addtional settings are created so that CI tests can pass
// Note that the '_id' and 'bendable_instance' values are ignored
const testSettings = {
  _id: { $oid: '6309adb838a3329b8806ed21' },
  bendable_instance: 'bendable-circle-ci-test',
  search: {
    faceted_search: 'enabled',
    zipcode_search: 'enabled',
  },
};

let currentSettings = {};

export const getBaseUrl = () => (apiBaseUrl)

export const urlEncode = (url) => {
  return encodeURIComponent(url)
}

const callDefinitions = {
  getSettings: {
    pathTemplate: '/base/settings',
    method: 'get',
  },
  getProviders: {
    pathTemplate: '/base/providers',
    method: 'get',
  },
  getTopics: {
    pathTemplate: '/base/topics',
    method: 'get',
  },
  getZipcode: {
    pathTemplate: '/base/zipcodes/:zipcode',
    method: 'get',
  },
};

/**
 * Simple mock API for callApi()
 *
 * @param {*} callDefinitionKey
 * @param {*} substitutionParams
 * @param {*} axiosOptions
 */
// eslint-disable-next-line no-unused-vars
const testCallApi = async (callDefinitionKey, substitutionParams, axiosOptions) => {
  if (callDefinitionKey === 'getSettings') return { status: 'success', data: { payload: {} } };
  if (callDefinitionKey === 'getProviders') return { status: 'success', data: { payload: { documents: [] } } };
  if (callDefinitionKey === 'getTopics') return { status: 'success', data: { payload: { documents: [] } } };
  if (callDefinitionKey === 'getZipcode') return { status: 'success', data: { payload: {} } };
  return { data: { payload: {} } };
};

/**
 * Generalized API call
 *
 * @param  {[type]}   callDefinitionKey  [description]
 * @param  {[type]}   substitutionParams [description]
 * @param  {[type]}   axiosOptions       [description]
 * @param  {Function} callback           [description]
 * @return {[type]}                      [description]
 *
 * Returned object is augmented with the original _apiKey, _substitutionParams,
 * and _jsonData
 */
const callApi = async (callDefinitionKey, substitutionParams = {}, axiosOptions = {}) => {
  if (isTestEnv) {
    try {
      const testResponse = await testCallApi(callDefinitionKey, substitutionParams, axiosOptions);
      return testResponse;
    } catch (err) {
      return { data: { payload: {} } };
    }
  }

  // get the call definition
  const callDefinition = callDefinitions[callDefinitionKey]
  if (!callDefinition) {
    // if (shouldLog) console.log('ERROR: ApiService.callApi() no api call for callDefinitionKey: ', callDefinitionKey);
    throw new Error(`No API call found for ${callDefinitionKey}`)
  }

  if ((callDefinition.method === 'get') || (callDefinition.method === 'delete')) {
    axiosOptions.params = axiosOptions.params || {}
    axiosOptions.params.apiKey = hubApiPublicKey
  }

  const pathTemplate = apiBaseUrl + callDefinition.pathTemplate
  const regex = /(?:^|\W):(\w+)(?!\w)/g
  const regexTokens = []
  let tempArray
  // eslint-disable-next-line no-cond-assign
  while ((tempArray = regex.exec(pathTemplate)) !== null) {
    regexTokens.push(tempArray[1])
  }

  // sanitize substitutionParams
  forOwn(substitutionParams, (value, key, obj) => {
    // substitutionParams values must be strings
    if (isNumber(value)) {
      obj[key] = `${value}`
    }
    // values with strings with must be URLencoded or we won't match routes when /, etc is present
    if (isString(value)) {
      obj[key] = encodeURIComponent(value)
    }
  })

  // if (shouldLog) console.log('hubApi.callApi() substitutionParams: ', substitutionParams);

  let path = pathTemplate
  // eslint-disable-next-line no-restricted-syntax
  for (const regexToken of regexTokens) {
    // if (shouldLog) console.log('hubApi.callApi() regexToken: ', regexToken);
    if (!substitutionParams[regexToken]) {
      // eslint-disable-next-line max-len
      // if (shouldLog) console.log('hubApi.callApi() error; missing required path; callDefinitionKey, regexToken: ', callDefinitionKey, regexToken);
      throw new Error(`Missing required path regexToken for ${callDefinitionKey} (${regexToken})`)
    }
    path = path.replace((`:${regexToken}`), substitutionParams[regexToken])
  }
  // if (shouldLog) console.log('callApi() path: ', path);

  // move axiosOptions to { data: axiosOptions } if POST or PUT and no 'data' field present
  // and set 'apiKey'
  if ((callDefinition.method === 'post') || (callDefinition.method === 'put')) {
    if (!axiosOptions.data) {
      axiosOptions = {
        data: { ...axiosOptions, apiKey: hubApiPublicKey },
      }
    } else {
      axiosOptions.data.apiKey = hubApiPublicKey
    }
  }

  axiosOptions.url = path
  axiosOptions.method = callDefinition.method
  axiosOptions.withCredentials = true // allows sending and receiving cookies
  axiosOptions.responseType = 'json'
  // if (verboseLogging) console.log('hubApi.callApi() axiosOptions: ', axiosOptions);

  try {
    const response = await axios(axiosOptions)
    // if (verboseLogging) console.log('hubApi.callApi() response: ', response);
    return response
  } catch (err) {
    // if (verboseLogging) console.log('hubApi.callApi() ERROR; err: ', err);
    return false
  }
}

/**
 * Gets a setting configured for the current BENDABLE_INSTANCE using local
 * cache or, if necessary, fetching via the hubApi
 *
 * @param {*} settingPath
 * @returns
 */
export const getSetting = async (settingPath) => {
  try {
    if (isTestEnv) {
      return get(testSettings, settingPath);
    }

    const setting = get(currentSettings, settingPath);
    if (setting !== undefined) return setting;

    const apiResponse = await callApi('getSettings', null, { params: { bendableInstance: bendableInstance } });
    // if (shouldLog) console.log('hubApi.getSetting() apiResponse: ', apiResponse);
    currentSettings = apiResponse?.data?.payload?.document || {};
    return get(currentSettings, settingPath);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('hubApi.setSetting() error during api fetch: ', err);
    return undefined;
  }
};

export default callApi;
