import React, { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react' // eslint-disable-line import/no-unresolved
import { Pagination, A11y, Navigation } from 'swiper' // eslint-disable-line import/no-unresolved
import PropTypes from 'prop-types'
import Spotlight from './Spotlight'

const Spotlights = function ({ spotlights, learner }) {
  const [collapsed, setCollapsed] = useState(true)

  return (
    <div className="spotlights">
      <div className="spotlights__nav-container">
        <button aria-label="Previous spotlight" type="button" className="spotlights__nav spotlights__nav--prev" />
        <button aria-label="Next spotlight" type="button" className="spotlights__nav spotlights__nav--next" />
      </div>
      <Swiper
        modules={[Pagination, A11y, Navigation]}
        pagination={{ clickable: true }}
        navigation={{
          nextEl: '.spotlights__nav--next',
          prevEl: '.spotlights__nav--prev',
        }}
        a11y={{
          firstSlideMessage: 'This is the first spotlight',
          lastSlideMessage: 'This is the last spotlight',
          nextSlideMessage: 'Next Spotlight',
          prevSlideMessage: 'Previous Spotlight',
          paginationBulletMessage: 'Go to spotlight {{index}}',
        }}
      >
        {spotlights.map((spotlight) => (
          <SwiperSlide key={spotlight.id}>
            <Spotlight spotlight={spotlight} learner={learner} collapsed={collapsed} setCollapsed={setCollapsed} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

Spotlights.propTypes = {
  spotlights: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number })),
  learner: PropTypes.object,
}

export default Spotlights
