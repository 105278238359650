import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'

import { Header } from '../shared'
import { GridExpanderComponent } from '../GridComponents'

const Collections = function ({ homepageSection, learner }) {
  return (
    <section className="content-section" id={homepageSection.anchor_tag}>
      <Header header={homepageSection.header} subheader={homepageSection.subheader} />
      {
        !isEmpty(homepageSection.collections) && (
          <GridExpanderComponent
            items={homepageSection.collections}
            numToDisplay={homepageSection.collections_display_number}
            seeMoreText=""
            type="collection"
            learner={learner}
          />
        )
      }
      {
        !isEmpty(homepageSection.learning_objects) && (
          <GridExpanderComponent
            items={homepageSection.learning_objects}
            numToDisplay={homepageSection.learning_objects_display_number}
            seeMoreText=""
            type="learning-object"
            learner={learner}
          />
        )
      }
      {
        !isEmpty(homepageSection.pairs) && (
          <GridExpanderComponent
            items={homepageSection.pairs}
            seeMoreText=""
            type="paired-learning-object"
            learner={learner}
          />
        )
      }
    </section>
  )
}

Collections.propTypes = {
  homepageSection: PropTypes.object.isRequired,
  learner: PropTypes.object,
}

export default Collections
