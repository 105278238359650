const LOAD = 'app/collections/LOAD'
const LOAD_SUCCESS = 'app/collections/LOAD_SUCCESS'
const LOAD_ERROR = 'app/collections/LOAD_ERROR'

const load = () => ({ type: LOAD })
const loadSuccess = (collectionGroup) => ({ type: LOAD_SUCCESS, collectionGroup })
const loadError = (errors) => ({ type: LOAD_ERROR, errors })

export const CollectionActions = {
  load,
  loadSuccess,
  loadError,
}

export const CollectionActionTypes = {
  LOAD,
  LOAD_SUCCESS,
  LOAD_ERROR,
}
