import { ProvidersActionTypes } from '../../../../actions'
import { LOADING_STATUSES } from '../../../shared'

// const shouldLog = false;

export const defaultState = {
  items: [],
  status: LOADING_STATUSES.notLoaded,
}

export default function providers(state = defaultState, action) {
  // if (shouldLog) console.log('providers reducers; state, action: ', state, action);

  switch (action.type) {
    case ProvidersActionTypes.LOAD:
      return { ...state, status: LOADING_STATUSES.loading }
    case ProvidersActionTypes.LOAD_SUCCESS:
      return { ...state, items: action.providers, status: LOADING_STATUSES.loaded }
    default:
      return state
  }
}
