import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import ReactMarkdown from 'react-markdown'
import { GridLearningObjects } from '../GridComponents'

const Spotlight = function ({
  spotlight, learner, collapsed, setCollapsed,
}) {
  return (
    <div className="spotlight">
      <div className="spotlight__description">
        <ReactMarkdown className="spotlight__headline" children={spotlight.headline} />
        <ReactMarkdown className="spotlight__subhead" children={spotlight.subhead} />
      </div>
      <div
        className={classnames(
          'spotlight__learning-objects',
          { 'spotlight__learning-objects--collapsed': collapsed },
        )}
      >
        <GridLearningObjects
          learningObjects={spotlight.learning_objects}
          learner={learner}
          bypassRedux
          itemClassName="spotlight__learning-object"
        />
        <div className="spotlight__show-more">
          <button className="button button--hollow-carnation" type="button" onClick={() => setCollapsed(false)}>
            <span className="button__text">See more</span>
            <span className="button__icon button__icon--arrow-down-gray-2" />
          </button>
        </div>
      </div>
    </div>
  )
}

Spotlight.propTypes = {
  spotlight: PropTypes.shape({
    headline: PropTypes.string,
    subhead: PropTypes.string,
    learning_objects: PropTypes.array,
  }),
  collapsed: PropTypes.bool,
  setCollapsed: PropTypes.func,
  learner: PropTypes.object,
}

export default Spotlight
