import React from 'react'

import { HOMEPAGE_DISPLAY_TYPES } from '../shared'
import Collections from './Collections'
import Story from './Story'

const HomepageSections = function ({ homepageSections, learner }) {
  return homepageSections.map((section) => (section.display_type === HOMEPAGE_DISPLAY_TYPES.collections
    ? (
      <Collections
        key={section.id}
        homepageSection={section}
        learner={learner}
      />
    ) : (
      <Story
        homepageSection={section}
        key={section.id}
      />
    )))
}

export default HomepageSections
