/* eslint-disable object-shorthand */
import {
  takeEvery, put, call,
} from 'redux-saga/effects'
import { isEmpty } from 'lodash'

import {
  algoliaSearchClient,
} from 'services/algoliaApi'

import { SearchActions, SearchActionTypes } from '../../../actions'

/* eslint import/no-unresolved: [2, { ignore: ['services/algoliaApi'] }] */
/* eslint-enable import/no-unresolved */
import { CONTENT_PER_PAGE, ALGOLIA_INSTANCE_FACET, ALGOLIA_OBJECT_TYPES_FACET } from '../../shared'

// const shouldLog = false;
// if (shouldLog) console.log('search.js ALGOLIA_INSTANCE_FACET: ', ALGOLIA_INSTANCE_FACET);

function* searchIndividual(action) {
  // if (shouldLog) console.log('search.searchIndividual() action: ', action);

  function addObjectTypes(facetFilters) {
    const objectTypes = ALGOLIA_OBJECT_TYPES_FACET.split(',')

    const objectFacets = objectTypes.map((type) => `object_type:${type}`)
    facetFilters.push(objectFacets)
  }

  try {
    let facetFilters = [
      'hidden:false',
      ALGOLIA_INSTANCE_FACET,
    ]

    addObjectTypes(facetFilters) // This and the env variable should be removed after all searches have all object_types

    // add facet restrictions from action...
    if (action?.searchFacets) facetFilters = facetFilters.concat(action?.searchFacets)
    // Algolia wants a * (wildcard) for an empty search
    const algoliaSearchTerm = isEmpty(action.searchTerm) ? '*' : action.searchTerm;
    const searchOptions = {
      page: action.page,
      facetFilters: facetFilters,
    };
    if (action?.searchZipcodeData
        && action.searchZipcodeData?.latitude
        && action.searchZipcodeData?.longitude
        && action.searchZipcodeData?.radius) {
      searchOptions.aroundLatLng = `${action.searchZipcodeData?.latitude}, ${action.searchZipcodeData?.longitude}`;
      searchOptions.aroundRadius = action.searchZipcodeData?.radius;
    }
    // if (shouldLog) console.log('search.searchIndividual() algoliaSearchTerm: ', algoliaSearchTerm);
    // if (shouldLog) console.log('search.searchIndividual() searchOptions: ', searchOptions);

    const algoliaResponse = yield call(
      algoliaSearchClient.search,
      algoliaSearchTerm,
      searchOptions,
    );

    const finalPageReached = algoliaResponse.page >= (algoliaResponse.nbPages - 1)

    yield put(SearchActions.searchSuccess(
      algoliaResponse.hits,
      finalPageReached,
      algoliaResponse.nbHits,
    ))
  } catch (error) {
    yield put(SearchActions.searchError(error))
  }
}

export default function* search() {
  yield takeEvery(SearchActionTypes.SEARCH, searchIndividual)
}
