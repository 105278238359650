import React from 'react'
import ReactMarkdown from 'react-markdown'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'

import Boards from '../MainApp/Boards'
import { HeadingRenderer } from '../shared'

function Heading(props) {
  return <HeadingRenderer properties={props} />
}

const FeaturedBoardGroup = function ({ featuredBoardGroup }) {
  if (isEmpty(featuredBoardGroup)) return null

  return (
    <div className="featured-board-group">
      <ReactMarkdown
        className="featured-board-group__headline"
        children={featuredBoardGroup.headline}
        components={{ p: Heading }}
      />
      <h5 className="featured-board-group__subhead">
        {featuredBoardGroup.subhead}
      </h5>

      <Boards boards={featuredBoardGroup.boards} />
    </div>
  )
}

FeaturedBoardGroup.propTypes = {
  featuredBoardGroup: PropTypes.shape(
    {
      headline: PropTypes.string,
      subhead: PropTypes.string,
      boards: PropTypes.array,
    },
  ),
}

export default FeaturedBoardGroup
