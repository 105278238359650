/* eslint-disable react/jsx-indent */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { getCurrentMuiBreakpoint } from '../shared';
import { GridCollections } from '../GridComponents';
import FeaturedCollection from './FeaturedCollection';

const TABS = [
  { name: 'Community Collections', key: 'community_collections' },
  { name: 'Career Collections', key: 'career_collections' },
];

const Collections = function ({ collections, learner }) {
  // eslint-disable-next-line no-console
  // console.log('Collections.jsx collections: ', collections);
  const currentMuiBreakpoint = getCurrentMuiBreakpoint();

  const [currentTab, setCurrentTab] = useState(TABS[0]); // default to Community Tab
  const [collapsed, setCollapsed] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [hasCommunityCollections, setHasCommunityCollections] = useState(false);
  const [hasCareerCollections, setHasCareerCollections] = useState(false);
  const [seeMoreCommunityCollections, setSeeMoreCommunityCollections] = useState(false);
  const [seeMoreCareerCollections, setSeeMoreCareerCollections] = useState(false);

  useEffect(() => {
    if (collections?.status === 'loaded') {
      setIsLoaded(true);
    }
  }, [collections]);

  useEffect(() => {
    if (collections?.community_collections && (collections.community_collections.length > 0)) {
      setHasCommunityCollections(true);
    }
    if (collections?.career_collections && (collections.career_collections.length > 0)) {
      setHasCareerCollections(true);
    }
  }, [collections]);

  useEffect(() => {
    let maxCardsPerRow = 3;
    if (currentMuiBreakpoint === 'xs' || currentMuiBreakpoint === 'sm') maxCardsPerRow = 1;
    if (currentMuiBreakpoint === 'md') maxCardsPerRow = 2;
    if (currentMuiBreakpoint === 'lg' || currentMuiBreakpoint === 'xl') maxCardsPerRow = 3;

    // Note for below: We do "length - 1" since the featured collection is on a separate row
    if ((currentTab.key === 'community_collections')
        && collections?.community_collections
        && (collections.community_collections.length - 1 > maxCardsPerRow)) {
      setSeeMoreCommunityCollections(true);
    }

    if ((currentTab.key === 'career_collections')
        && collections?.career_collections
        && (collections.career_collections.length - 1 > maxCardsPerRow)) {
      setSeeMoreCareerCollections(true);
    }
  }, [currentTab, collections]);

  useEffect(() => {
    if (hasCareerCollections && !hasCommunityCollections) {
      setCurrentTab(TABS[1]); // default to Career Tab
    }
  }, [hasCareerCollections, hasCommunityCollections]);

  if (!isLoaded) return (<div />);

  return (hasCommunityCollections || hasCareerCollections
    ? (
      <div className="collections">
        <div className="collections__controls">
          <h2 className="collections__headline">
            Collections
          </h2>

          { (hasCommunityCollections && hasCareerCollections)
            ? (
            <div className="collections__tab-buttons">
              {TABS.map((tab) => (
                <button
                  key={tab.key}
                  className={classNames(
                    'collections__tab-button',
                    { 'collections__tab-button--active': tab === currentTab },
                  )}
                  type="button"
                  onClick={() => setCurrentTab(tab)}
                >
                  <span>{tab.name}</span>
                </button>
              ))}
            </div>
            )
            : (
              <div />
            )}
          <hr className="collections__hr" />
        </div>

        <FeaturedCollection collection={collections[currentTab.key][0]} />
        <hr className="collections__hr" />

        <div className={classNames(
          'collections__grid-container',
          { 'collections__grid-container--collapsed': collapsed },
        )}
        >
          <GridCollections
            collections={collections[currentTab.key].slice(1)}
            learner={learner}
            collectionCardClassName="collections__collection"
          />
          { seeMoreCommunityCollections || seeMoreCareerCollections
            ? (
                <div className="collections__show-more">
                  <button className="button button--hollow-carnation" type="button" onClick={() => setCollapsed(false)}>
                    <span className="button__text">See more</span>
                    <span className="button__icon button__icon--arrow-down-carnation" />
                  </button>
                </div>
            )
            : <div />}
        </div>
      </div>
    )
    : (
      <div />
    )
  )
}

Collections.propTypes = {
  collections: PropTypes.object,
  learner: PropTypes.object,
};

export default Collections;
