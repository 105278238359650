import { CareerCollectionActionTypes } from '../../../../actions'

export const defaultState = {
  item: {},
}

export default function careerCollection(state = defaultState, action) {
  switch (action.type) {
    case CareerCollectionActionTypes.LOAD_SUCCESS:
      return { ...state, item: action.careerCollection }
    case CareerCollectionActionTypes.CLEAR:
      return { ...state, item: {} }
    default:
      return state
  }
}
