import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'

import LearningFormatIcon from './LearningFormatIcon'

const shortenTimeUnit = (timeUnit) => (timeUnit === 'hours' ? 'hrs' : timeUnit)

const Indicators = function ({ learningObject }) {
  return (
    <div className="indicators">
      <div className="indicator">
        <LearningFormatIcon
          learningFormat={learningObject.learning_format}
          location={learningObject.location}
          className="indicator__icon"
        />
      </div>
      <div className="indicator">
        <div className={`indicator__icon indicator__icon--${learningObject.instruction_type}`} />
        <div className="indicator__icon-text">
          {learningObject.instruction_type === 'live-instruction'
            ? 'live instruction'
            : learningObject.instruction_type}
        </div>
      </div>
      {
        !!learningObject.time_estimate_amount && (
          <div className="indicator">
            <div className="indicator__icon indicator__icon--time-estimate" />
            <div className="indicator__icon-text">
              Time est.
              {' '}
              {learningObject.time_estimate_amount}
              {' '}
              {shortenTimeUnit(learningObject.time_estimate_unit)}
            </div>
          </div>
        )
}
      <div className="indicator">
        <div className={`indicator__icon indicator__icon--${learningObject.session_length}`} />
        <div className="indicator__icon-text">
          {learningObject.session_length}
          {' '}
          sessions
        </div>
      </div>
      {
        !isEmpty(learningObject.credits) && learningObject.credits.map((credit) => (
          <div className="indicator" key={credit}>
            <div className={`indicator__icon indicator__icon--${credit.replace(' ', '-')}`} />
            <div className="indicator__icon-text">
              {credit}
            </div>
          </div>
        ))
      }
      {
      learningObject.badgeable && (
        <div className="indicator">
          <div className="indicator__icon indicator__icon--bendable-badge" />
          <div className="indicator__icon-text">
            Bendable badge
          </div>
        </div>
      )
    }
      {
      learningObject.fee_required && (
        <div className="indicator">
          <div className="indicator__icon indicator__icon--fee" />
          <div className="indicator__icon-text">
            Fee required
          </div>
        </div>
      )
    }
    </div>
  )
}

Indicators.propTypes = {
  learningObject: PropTypes.object,
}

export default Indicators
