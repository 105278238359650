/* eslint-disable object-shorthand */
import {
  take, put,
} from 'redux-saga/effects'

import callHubApi from 'services/hubApi'
/* eslint import/no-unresolved: [2, { ignore: ['services/hubApi'] }] */
/* eslint-enable import/no-unresolved */

import { TopicsActions, TopicsActionTypes } from '../../../actions'

// const shouldLog = false;

const bendableInstance = process.env.BENDABLE_INSTANCE
// if (shouldLog) console.log('loadTopics bendableInstance: ', bendableInstance);

export default function* loadTopics() {
  while (true) {
    yield take(TopicsActionTypes.LOAD)

    try {
      const response = yield callHubApi(
        'getTopics',
        null,
        {
          params: {
            bendableInstance: bendableInstance,
          },
        },
      )

      // if (shouldLog) console.log('loadTopics.loadTopics() response: ', response);
      const documents = response?.data?.payload?.documents;
      // if (shouldLog) console.log('loadTopics.loadTopics() documents: ', documents);
      yield put(TopicsActions.loadSuccess(documents))
    } catch (error) {
      yield put(TopicsActions.loadError(error?.response?.data?.errors)) // TODO: fix?
    }
  }
}
