import {
  take, put, call,
} from 'redux-saga/effects'

import { BoardActions, BoardActionTypes } from '../actions'
import api from '../services/api'

export default function* loadBoard() {
  while (true) {
    const { id } = yield take(BoardActionTypes.LOAD)

    try {
      const response = yield call(
        api.boards.show,
        id,
      )

      yield put(BoardActions.loadSuccess(response.data))
    } catch (error) {
      yield put(BoardActions.loadError(error.response.data.errors))
    }
  }
}
