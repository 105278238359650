/* eslint-disable react/function-component-definition */
import React from 'react'

import DefaultEmptyResults from './emptyResults/DefaultEmptyResults'
import MaineEmptyResults from './emptyResults/MaineEmptyResults'

// const shouldLog = false;

const bendableInstance = process.env.BENDABLE_INSTANCE
// if (shouldLog) console.log('SearchEmpty bendableInstance: ', bendableInstance);

const SearchEmpty = () => {
  switch (bendableInstance) {
    case 'bendable-maine':
      return <MaineEmptyResults />
    default:
      return <DefaultEmptyResults />
  }
}

export default SearchEmpty
