import React from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { useSessionStorage } from '../shared'
import { SearchActions } from '../../actions'
import routes from '../../services/routes'

// const shouldLog = false;

const FeaturedTopic = function ({ topic }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [sessionStorageSearchFacets, setSessionStorageSearchFacets] = useSessionStorage('searchFacets', {})
  // eslint-disable-next-line no-unused-vars
  const [shouldShowSearchFacets, setShouldShowSearchFacets] = useSessionStorage('shouldShowSearchFacets', false)

  const handleTopicButtonClick = () => {
    // setSessionStorageSearchFacets(() => ({}));
    const newLocalState = { ...sessionStorageSearchFacets }
    newLocalState.topic = [{ label: topic, value: topic }]
    // if (shouldLog) console.log('FeaturedTopic.handleTopicButtonClick() about to set newLocalState: ', newLocalState);
    setSessionStorageSearchFacets(newLocalState) // uses sessionStorage to survive Redux initialization on page loads
    setShouldShowSearchFacets(true) // open the SearchFacets pane
    dispatch(SearchActions.setSearchFacets(newLocalState))
    navigate(routes.explore.search())
  }

  return (
    <button
      type="button"
      className="featured-topic__button"
      onClick={handleTopicButtonClick}
    >
      <span className="featured-topic__button-text">
        {topic}
      </span>
    </button>
  )
}

export const topicProperty = PropTypes.string

FeaturedTopic.propTypes = {
  topic: topicProperty,
}

export default FeaturedTopic
