import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { isEmpty } from 'lodash'

import { BoardActions, FavoritesActions } from '../../actions'
import { findTheme, useLoggedIn, trackLearningObjectReferralPage } from '../shared'

import { CommunityCollectionCard, CareerCollectionCard } from '../Collection'
import { LearningObjectCard } from '../LearningObjects'

const Board = function () {
  const dispatch = useDispatch()
  const { id } = useParams()
  const board = useSelector((state) => state.local.board.item)
  const favorites = useSelector((state) => state.app.favorites.items)
  const loggedIn = useLoggedIn()

  useEffect(() => {
    trackLearningObjectReferralPage('board')
  })

  useEffect(() => {
    if (isEmpty(board) || board.id?.toString() !== id) {
      dispatch(BoardActions.load(id))
    }
    if (isEmpty(favorites) && loggedIn) {
      dispatch(FavoritesActions.load())
    }

    return function cleanUp() {
      dispatch(BoardActions.clear())
    }
  }, [dispatch, BoardActions.load, board])

  if (isEmpty(board)) return null

  const themeNumber = findTheme(board.ordered_number)

  return (
    <div className="board">
      <div className={`board__header board__header--theme-${themeNumber}`}>
        <div className="board__title">{board.organization}</div>
        <div className="board__caption">{board.program}</div>
      </div>
      { (!isEmpty(board.community_collections) || !isEmpty(board.career_collections))
        && (
          <div className="content__grid">
            <div className="grid grid--collections">
              {
                board.community_collections.map(
                  (coll) => <CommunityCollectionCard key={coll.id} collection={coll} loggedIn={loggedIn} />,
                )
              }
              {
                board.career_collections.map(
                  (coll) => <CareerCollectionCard key={coll.id} collection={coll} loggedIn={loggedIn} />,
                )
              }
            </div>
          </div>
        )}
      {
        !isEmpty(board.learning_objects) && (
          <div className="content__grid">
            <div className="grid grid--learning-objects">
              {
                board.learning_objects.map(
                  (learningObject, i) => (
                    <div className="grid__learning-object--with-divider" key={learningObject.id}>
                      <div className="grid__divider">
                        <div className="divider" />
                      </div>
                      <div className="grid__learning-object">
                        <LearningObjectCard
                          key={learningObject.id}
                          learningObject={learningObject}
                          index={i}
                          loggedIn={loggedIn}
                        />
                      </div>
                      <div className="learning-object-card__x-out-button js-unstart-learning-object" />
                    </div>
                  ),
                )
              }
            </div>
          </div>
        )
      }
    </div>
  )
}

export default Board
