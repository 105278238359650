import React from 'react'
import ReactMarkdown from 'react-markdown'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import FeaturedTopic, { topicProperty } from './FeaturedTopic'
import { HeadingRenderer } from '../shared'

function Heading(props) {
  return <HeadingRenderer className="featured-topics__headline" properties={props} idName="featured-content" />
}

const FeaturedTopics = function ({ featuredTopics: { headline, subhead, topics } }) {
  if (isEmpty(topics)) return null

  return (
    <div className="featured-topics__background">
      <div className="featured-topics">
        <ReactMarkdown
          children={headline}
          components={{ p: Heading }}
        />
        <ReactMarkdown className="featured-topics__subhead" children={subhead} />
        <div className="featured-topics__buttons">
          {topics.map((topic) => (
            <FeaturedTopic key={topic} topic={topic} />
          ))}
        </div>
      </div>
    </div>
  )
}

FeaturedTopics.propTypes = {
  featuredTopics: PropTypes.shape(
    {
      headline: PropTypes.string,
      subhead: PropTypes.string,
      topics: PropTypes.arrayOf(topicProperty).isRequired,
    },
  ),
}

export default FeaturedTopics
