import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import classNames from 'classnames'

import { LearningObjectCard } from '../LearningObjects'

const GridLearningObjects = function ({
  learningObjects, learner, bypassRedux, itemClassName, componentName,
}) {
  const CardComponent = componentName || LearningObjectCard

  return (
    <div className="grid grid--learning-objects">
      {
        learningObjects.map((lo, i) => (
          <div key={lo.id} className={classNames('grid__learning-object--with-divider', itemClassName)}>
            <div className="grid__divider">
              <div className="divider" />
            </div>
            <div className="grid__learning-object">
              <CardComponent
                learningObject={lo}
                index={i}
                loggedIn={!isEmpty(learner)}
                bypassRedux={bypassRedux}
              />
              <div className="learning-object-card__x-out-button js-unstart-learning-object" />
            </div>
          </div>
        ))
      }
    </div>
  )
}

GridLearningObjects.propTypes = {
  learningObjects: PropTypes.array.isRequired,
  learner: PropTypes.object,
  bypassRedux: PropTypes.bool,
  itemClassName: PropTypes.string,
  componentName: PropTypes.string,
}

export default GridLearningObjects
