import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import RefreshIcon from '@mui/icons-material/Refresh';
import IconButton from '@mui/material/IconButton';

// const shouldLog = false;

const getValueForMarkIndex = (index) => {
  switch (index) {
    case 1:
      return 5;
    case 2:
      return 10;
    case 3:
      return 25;
    case 4:
      return 50;
    case 5:
      return 5000;
    default:
      return 0;
  }
};

const marks = [1, 2, 3, 4, 5].map((value) => ({
  value,
  label: (value === 5) ? 'No limit' : `${getValueForMarkIndex(value)} mi`,
}));

function valuetext(value) {
  if (value === 5000) return 'No limit';
  return `${value} miles`;
}

function valueLabelFormat(value) {
  let text = 'No limit';
  const actualValue = getValueForMarkIndex(value);
  if (actualValue < 5000) text = `${actualValue} mi`;
  return text;
}

const SearchZipcode = (props) => { // eslint-disable-line react/function-component-definition
  const {
    zipcodeData,
    onChange,
    onRefresh,
  } = props;
  // if (shouldLog) console.log('SearchZipcode props; zipcodeData: ', zipcodeData);

  const handleZipcodeChange = (event) => {
    const newState = {
      ...zipcodeData,
      zipcode: event?.target?.value,
    };
    onChange(newState);
  };

  const handleSliderChange = (event, newValue) => {
    // if (shouldLog) console.log('SearchZipcode.getLocationData() newValue: ', newValue);
    const newState = {
      ...zipcodeData,
      radiusIndex: newValue,
      radius: Math.round(getValueForMarkIndex(newValue) * 1.60934 * 1000), // convert miles to meters
    };
    onChange(newState);
  };

  const handleRefreshIconClick = () => {
    onRefresh();
  };

  return (
    <Stack
      direction={{ xs: 'column', md: 'row' }}
      sx={{
        width: '100%',
        backgroundColor: '#ffffff',
        borderRadius: '10px',
        padding: '10px',
      }}
    >
      <Stack
        direction="row"
        sx={{
          position: 'relative',
          width: { xs: '100%', md: '40%' },
        }}
      >
        <Box
          sx={{
            fontSize: '0.8rem',
            paddingTop: '15px',
          }}
        >
          Zipcode
        </Box>

        <TextField
          value={zipcodeData?.zipcode || ''}
          variant="outlined"
          onChange={handleZipcodeChange}
          inputProps={{
            placeholder: 'Enter zipcode',
            maxLength: 5,
            style: {
              padding: '7px 10px',
              fontSize: '0.8rem',
            },
          }}
          sx={{
            marginTop: '10px',
            marginLeft: '10px',
            width: '100%',
          }}
        />

        <Typography
          sx={{
            display: zipcodeData?.city ? 'block' : 'none',
            position: 'absolute',
            right: '10px',
            top: '17px',
            color: '#555555',
            fontSize: '0.7rem',
          }}
        >
          (
          {zipcodeData?.city}
          )
        </Typography>
      </Stack>

      <Stack
        direction="row"
        sx={{
          width: { xs: '100%', md: '60%' },
          opacity: zipcodeData?.zipcode?.length === 5 ? 1 : 0.3,
        }}
      >
        <Slider
          aria-label="Search radius values"
          defaultValue={zipcodeData?.radiusIndex || 5}
          value={zipcodeData?.radiusIndex || 5}
          onChange={handleSliderChange}
          valueLabelFormat={valueLabelFormat}
          getAriaValueText={valuetext}
          step={null}
          valueLabelDisplay="auto"
          marks={marks}
          // scale={getValueForMarkIndex}
          min={1}
          max={5}
          disabled={!zipcodeData?.zipcode}
          sx={{
            color: '#888888',
            width: '80%',
            marginLeft: { xs: '25px', md: '50px' },
          }}
        />

        <Box
          sx={{
            marginLeft: '40px',
            marginTop: '3px',
          }}
        >
          <Tooltip title="Refresh Search" placement="top">
            <IconButton aria-label="Refresh" onClick={handleRefreshIconClick}>
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Stack>
    </Stack>
  );
};

SearchZipcode.propTypes = {
  zipcodeData: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
};

export default SearchZipcode;
