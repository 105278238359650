import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Cleave from 'cleave.js'

import EmailEditWarning from './EmailEditWarning'
import routes from '../../services/routes'
import SendSms from './SendSms'
import {
  Header,
  RailsStyleInput,
  RailsStyleCheckbox,
  USER_STATUSES,
  CHECK_PIN,
} from '../shared'

const Profile = function ({ user, errors, userStatus }) {
  const [firstName, setFirstName] = useState(user.first_name)
  const [lastName, setLastName] = useState(user.last_name)
  const [email, setEmail] = useState(user.email)
  const [libraryCardNumber, setLibraryCardNumber] = useState(user.library_card_number)
  const [libraryCardPin, setLibraryCardPin] = useState(user.library_card_pin)
  const [emailNotification, setEmailNotification] = useState(user.email_notification)
  const [communityParticipant, setCommunityParticipant] = useState(user.community_participant)
  const [csrfToken, _setCsrfToken] = useState(document.querySelector('[name=csrf-token]').content)

  useEffect(() => {
    document
      .querySelectorAll('input.js-library-card-number')
      .forEach((el) => {
        /* eslint-disable no-new */
        new Cleave(el, {
          numericOnly: true,
          blocks: [1, 4, 5, 4],
        })
        /* eslint-enable no-new */
      })
  }, [user, errors])

  const participantAgreement = 'I am participating in a learning program with a Bendable community partner. Bendable '
    + 'can share my program-related learning data with that organization.'

  return (
    <div className="my-stuff">
      <EmailEditWarning />
      <div className="my-stuff__profile">
        <Header
          header="My account"
          subheader={'Bendable takes your privacy seriously. Here’s all the information that '
        + 'you shared with us and that we have stored. Feel free to update at any time.'}
        />
        <div className="my-stuff__profile-form">
          <form
            className="js-edit-profile"
            action={routes.myAccount.update()}
            acceptCharset="UTF-8"
            id={`edit_member_${user.id}`}
            method="post"
          >
            <input type="hidden" name="_method" value="patch" />
            <input
              type="hidden"
              name="authenticity_token"
              id="authenticity_token"
              value={csrfToken}
            />
            <div className="form__inputs">
              <RailsStyleInput
                key="first"
                fieldFor="user"
                fieldName="first name"
                type="text"
                value={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value)
                }}
                errors={errors?.first_name}
              />
              <RailsStyleInput
                key="last"
                fieldFor="user"
                fieldName="last name"
                type="text"
                value={lastName}
                onChange={(e) => {
                  setLastName(e.target.value)
                }}
                errors={errors?.last_name}
              />
              <RailsStyleInput
                key="email"
                fieldFor="user"
                fieldName="email"
                type="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value)
                }}
                errors={errors?.email}
              />
              <RailsStyleInput
                key="library"
                fieldFor="user"
                fieldName="library card number"
                type="text"
                value={libraryCardNumber || ''}
                onChange={(e) => {
                  setLibraryCardNumber(e.target.value)
                }}
                errors={errors?.library_card_number}
                autoFocus={userStatus === USER_STATUSES.temporary || userStatus === USER_STATUSES.expired}
              />
              {
                CHECK_PIN && (
                  <RailsStyleInput
                    key="library"
                    fieldFor="user"
                    fieldName="library card pin"
                    type="text"
                    value={libraryCardPin || ''}
                    onChange={(e) => {
                      setLibraryCardPin(e.target.value)
                    }}
                    errors={errors?.library_card_pin}
                  />
                )
              }
              <RailsStyleCheckbox
                key="email-notification"
                label="I want Bendable to email me."
                className="form__input-container--checkbox"
                fieldFor="user"
                fieldName="email notification"
                value={emailNotification}
                onChange={() => {
                  setEmailNotification(!emailNotification)
                }}
              />
              <SendSms
                mobilePhoneNumber={user.mobile_phone_number}
                sendSms={user.send_sms}
                errors={errors?.mobile_phone_number}
              />
              <div className="form__divider-spacer">
                <div className="divider" />
              </div>
              <RailsStyleInput
                key="password"
                fieldFor="user"
                fieldName="password"
                type="password"
                errors={errors?.password}
                placeholder="new password"
              >
                <p className="my-stuff__profile-edit-password body-copy-2">Change password.</p>
              </RailsStyleInput>
              <RailsStyleInput
                key="password-confirmation"
                fieldFor="user"
                fieldName="password confirmation"
                type="password"
                errors={errors?.password_confirmation}
                placeholder="new password confirmation"
              />
            </div>
            <div className="form__divider-spacer">
              <div className="divider" />
            </div>
            <RailsStyleCheckbox
              key="community-participant"
              label={participantAgreement}
              className="form__input-container--checkbox"
              fieldFor="user"
              fieldName="community participant"
              value={communityParticipant}
              onChange={() => {
                setCommunityParticipant(!communityParticipant)
              }}
            />
            <div className="form__submit-container">
              <input
                type="submit"
                name="commit"
                value="Save"
                className="form__submit-button js-my-profile-submit-button-edit"
                data-disable-with="Save"
              />
            </div>
            <div className="my-stuff__profile-logout">
              <a
                href={routes.logOut()}
                className="button button--hollow-gold"
                rel="nofollow"
                data-method="delete"
              >
                <span className="button__text">
                  Log out
                </span>
              </a>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

Profile.propTypes = {
  user: PropTypes.object.isRequired,
  errors: PropTypes.object,
  userStatus: PropTypes.string.isRequired,
}

export default Profile
