import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { Favorite, selectFavorite } from '../shared'

const CollectionCardPortrait = function ({
  collection, type, imageClass, bypassRedux, loggedIn,
}) {
  const favorite = bypassRedux ? collection.favorite : useSelector(selectFavorite(collection, type))

  return (
    <div className="collection-card__portrait">
      <div
        className={`overlaid-image collection-card__image ${imageClass}`}
        style={{ backgroundImage: `url('${collection.image_url}')` }}
      >
        <img alt={collection.image_alt_text || collection.title} src={collection.image_url} />
      </div>
      <Favorite favorite={favorite} object={collection} objectType={type} loggedIn={loggedIn} />
    </div>
  )
}

CollectionCardPortrait.propTypes = {
  collection: PropTypes.object,
  type: PropTypes.string.isRequired,
  imageClass: PropTypes.string,
  loggedIn: PropTypes.bool,
  bypassRedux: PropTypes.bool,
}

export default CollectionCardPortrait
