import {
  CollectionActionTypes,
  FeaturedLibrariesActionTypes,
  FeaturedTopicsActionTypes,
  FeaturedBoardGroupsActionTypes,
  HeroCarouselActionTypes,
  HomepageSectionsActionTypes,
  SpotlightsActionTypes,
} from '../../../../actions'
import { LOADING_STATUSES } from '../../../shared'

export const defaultState = {
  heroCarousel: {
    status: LOADING_STATUSES.notLoaded,
    slides: [],
  },
  spotlightCarousel: {
    status: LOADING_STATUSES.notLoaded,
    spotlights: [],
  },
  homepageSections: {
    status: LOADING_STATUSES.notLoaded,
    sections: [],
  },
  featuredLibraries: {
    status: LOADING_STATUSES.notLoaded,
    featured_libraries: [],
  },
  featuredTopics: {
    status: LOADING_STATUSES.notLoaded,
    headline: '',
    subhead: '',
    topics: [],
  },
  featuredBoardGroups: {
    status: LOADING_STATUSES.notLoaded,
    featured_board_groups: [],
  },
  collections: {
    status: LOADING_STATUSES.notLoaded,
    career_collections: [],
    community_collections: [],
  },
}

export default function homepage(state = defaultState, action) {
  switch (action.type) {
    case HeroCarouselActionTypes.LOAD:
      return {
        ...state,
        heroCarousel: {
          ...state.heroCarousel,
          status: LOADING_STATUSES.loading,
        },
      }
    case HeroCarouselActionTypes.LOAD_SUCCESS:
      return {
        ...state,
        heroCarousel: {
          status: LOADING_STATUSES.loaded,
          slides: action.heroCarousel.slides,
        },
      }
    case HeroCarouselActionTypes.LOAD_ERROR:
      return {
        ...state,
        heroCarousel: {
          ...state.heroCarousel,
          status: LOADING_STATUSES.notLoaded,
        },
      }
    case SpotlightsActionTypes.LOAD:
      return {
        ...state,
        spotlightCarousel: {
          ...state.spotlightCarousel,
          status: LOADING_STATUSES.loading,
        },
      }
    case SpotlightsActionTypes.LOAD_SUCCESS:
      return {
        ...state,
        spotlightCarousel: {
          status: LOADING_STATUSES.loaded,
          spotlights: action.spotlights,
        },
      }
    case SpotlightsActionTypes.LOAD_ERROR:
      return {
        ...state,
        spotlightCarousel: {
          ...state.spotlightCarousel,
          status: LOADING_STATUSES.notLoaded,
        },
      }
    case HomepageSectionsActionTypes.LOAD:
      return {
        ...state,
        homepageSections: {
          ...state.homepageSections,
          status: LOADING_STATUSES.loading,
        },
      }
    case HomepageSectionsActionTypes.LOAD_SUCCESS:
      return {
        ...state,
        homepageSections: {
          status: LOADING_STATUSES.loaded,
          sections: action.sections,
        },
      }
    case HomepageSectionsActionTypes.LOAD_ERROR:
      return {
        ...state,
        homepageSections: {
          ...state.homepageSections,
          status: LOADING_STATUSES.notLoaded,
        },
      }
    case FeaturedTopicsActionTypes.LOAD:
      return {
        ...state,
        featuredTopics: {
          ...state.featuredTopics,
          status: LOADING_STATUSES.loading,
        },
      }
    case FeaturedTopicsActionTypes.LOAD_SUCCESS:
      return {
        ...state,
        featuredTopics: {
          ...state.featuredTopics,
          status: LOADING_STATUSES.loaded,
          ...action.featuredTopics,
        },
      }
    case FeaturedTopicsActionTypes.LOAD_ERROR:
      return {
        ...state,
        featuredTopics: {
          ...state.featuredTopics,
          status: LOADING_STATUSES.notLoaded,
        },
      }

    case FeaturedLibrariesActionTypes.LOAD:
      return {
        ...state,
        featuredLibraries: {
          ...state.featuredLibraries,
          status: LOADING_STATUSES.loading,
        },
      }
    case FeaturedLibrariesActionTypes.LOAD_SUCCESS:
      return {
        ...state,
        featuredLibraries: {
          ...state.featuredLibraries,
          status: LOADING_STATUSES.loaded,
          ...action.featuredLibraries,
        },
      }
    case FeaturedLibrariesActionTypes.LOAD_ERROR:
      return {
        ...state,
        featuredLibraries: {
          ...state.featuredLibraries,
          status: LOADING_STATUSES.notLoaded,
        },
      }

    case FeaturedBoardGroupsActionTypes.LOAD:
      return {
        ...state,
        featuredBoardGroups: {
          ...state.featuredBoardGroups,
          status: LOADING_STATUSES.loading,
        },
      }
    case FeaturedBoardGroupsActionTypes.LOAD_SUCCESS:
      return {
        ...state,
        featuredBoardGroups: {
          ...state.featuredBoardGroups,
          status: LOADING_STATUSES.loaded,
          ...action.featuredBoardGroups,
        },
      }
    case FeaturedBoardGroupsActionTypes.LOAD_ERROR:
      return {
        ...state,
        featuredBoardGroups: {
          ...state.featuredBoardGroups,
          status: LOADING_STATUSES.notLoaded,
        },
      }

    case CollectionActionTypes.LOAD:
      return {
        ...state,
        collections: {
          ...state.collections,
          status: LOADING_STATUSES.loading,
        },
      }
    case CollectionActionTypes.LOAD_SUCCESS:
      return {
        ...state,
        collections: {
          ...state.collections,
          status: LOADING_STATUSES.loaded,
          ...action.collectionGroup,
        },
      }
    case CollectionActionTypes.LOAD_ERROR:
      return {
        ...state,
        collections: {
          ...state.collections,
          status: LOADING_STATUSES.notLoaded,
        },
      }
    default:
      return state
  }
}
