/* eslint-disable react/jsx-fragments */
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { LoadingGrid } from '../GridComponents'
import { SearchActions } from '../../actions'
import {
  LOADING_STATUSES,
  SEARCH_FIRST_PAGE,
  buildAlgoliaFacetArg,
} from '../shared'

const Results = function () {
  const searchState = useSelector((state) => state.local.search)
  const { searchFacets, searchZipcodeData } = searchState;

  const [facetArg, setFacetArg] = useState([]);
  const [zipcodeData, setZipcodeData] = useState({});

  useEffect(() => {
    const newFacetArg = buildAlgoliaFacetArg(searchFacets)
    setFacetArg(newFacetArg)
  }, [searchFacets])

  useEffect(() => {
    setZipcodeData(searchZipcodeData);
  }, [searchZipcodeData]);

  return (
    <div className="content__grid">
      <LoadingGrid
        objectsState={searchState}
        objects={searchState.hits}
        load={(page) => SearchActions.search(searchState.searchTerm, page, facetArg, zipcodeData)}
        incrementPage={SearchActions.incrementPage}
        firstPage={SEARCH_FIRST_PAGE}
      />
    </div>
  )
}

export default Results
