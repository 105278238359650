import React from 'react'
import PropTypes from 'prop-types'

const Testimonial = function ({ testimonial, instance }) {
  const urlInstance = (instance === 'maine' || instance === 'southbend') ? instance : 'southbend'
  const url = `https://bendable.s3.us-west-1.amazonaws.com/live-and-learn/${urlInstance}/${testimonial.image_slug}.jpeg`
  return (
    <div className="testimonial">
      <div className="testimonial__container">
        <img
          className="testimonial__profile-image"
          src={url}
          alt="testimonial profile"
        />
        <div className="testimonial__content-container">
          <div className="testimonial__divider" />
          <div>
            {testimonial.content}
          </div>
          <div className="testimonial__date">
            <b>{testimonial.date}</b>
          </div>
        </div>
      </div>
      <hr className="testimonial__hr" />
    </div>
  )
}

Testimonial.propTypes = {
  testimonial: PropTypes.object,
  instance: PropTypes.string,
}

export default Testimonial
