const { api } = require('../helpers')

$(() => {
  function parseParams(search) {
    const result = {}
    const paramList = search.split('&')
    for (let i = 0; i < paramList.length; i++) {
      /* eslint-disable prefer-destructuring */
      result[paramList[i].split('=')[0]] = paramList[i].split('=')[1]
      /* eslint-enable prefer-destructuring */
    }
    return result
  }

  /* eslint-disable no-console */
  function sendRequest($target) {
    if ($target.data('ajaxPath')) {
      const splitPath = $target.data('ajaxPath').split('?')
      const url = splitPath[0]
      const data = splitPath[1] ? parseParams(splitPath[1]) : {}
      api
        .post(url, data)
        .done((resp) => {
          if (resp.path) {
            $target.data('ajaxPath', resp.path)
          }
        })
        .fail((err) => console.warn(err))
    }
  }
  /* eslint-enable no-console */

  function handleSubmit(event, target) {
    event.preventDefault()

    const $target = $(target)
    $target.toggleClass(`${$target[0].classList[0]}--selected`)
    const toggleValue = $target.attr('aria-pressed') !== 'true'
    $target.attr('aria-pressed', toggleValue)

    sendRequest($target)
  }

  $(document).on('click', '.js-toggle-element', (event) => handleSubmit(event, event.currentTarget))
  $(document).on('keypress', '.js-toggle-element', (event) => {
    if (event.keyCode === 13) {
      handleSubmit(event, event.target)
    }
  })
})
