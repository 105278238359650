const LOAD = 'local/board/LOAD'
const LOAD_SUCCESS = 'local/board/LOAD_SUCCESS'
const LOAD_ERROR = 'local/board/LOAD_ERROR'
const CLEAR = 'local/board/CLEAR'

const load = (id) => ({ type: LOAD, id })
const loadSuccess = (board) => ({ type: LOAD_SUCCESS, board })
const loadError = (errors) => ({ type: LOAD_ERROR, errors })
const clear = () => ({ type: CLEAR })

export const BoardActions = {
  load,
  loadSuccess,
  loadError,
  clear,
}

export const BoardActionTypes = {
  LOAD,
  LOAD_SUCCESS,
  LOAD_ERROR,
}
