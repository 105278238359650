import React from 'react'
import ReactMarkdown from 'react-markdown'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import CollectionCardPortrait from './CollectionCardPortrait'
import CollectionCardResourceCount from './CollectionCardResourceCount'
import { OBJECT_TYPES } from '../shared'
import routes from '../../services/routes'

const CommunityCollectionCard = function ({
  collection, bypassRedux, loggedIn, className,
}) {
  return (
    <a
      className={classNames('collection-card', className)}
      title={collection.plain_text_title}
      href={routes.communityCollections.show(collection.id)}
    >
      <CollectionCardPortrait
        collection={collection}
        type={OBJECT_TYPES.communityCollection}
        imageClass="collection-card__image--community"
        loggedIn={loggedIn}
        bypassRedux={bypassRedux}
      />
      <div className="collection-card__text-section">
        <h4 className="heading-4 heading-4--gray-2 collection-card__heading">
          <ReactMarkdown children={collection.title} />
        </h4>
        <div className="collection-card__credits--community">
          <div className="collection-card__credits-text">
            <div className="collection-card__bottom-section">
              <div className="collection-card__by">Collection by</div>
              <ReactMarkdown
                children={collection.author}
                className="subtitle-1"
              />
              {
              collection.author_pronouns
                ? <div className="collection-card__pronouns">{collection.author_pronouns}</div>
                : null
              }
            </div>
            <CollectionCardResourceCount resourceCount={collection.resources_count} />
          </div>
        </div>
      </div>
      <div className="collection-card__bottom-banner collection-card__bottom-banner--community" />
    </a>
  )
}

CommunityCollectionCard.propTypes = {
  collection: PropTypes.object,
  loggedIn: PropTypes.bool,
  bypassRedux: PropTypes.bool,
  className: PropTypes.string,
}

export default CommunityCollectionCard
