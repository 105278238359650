/* eslint-disable react/jsx-boolean-value */
/* eslint-disable object-shorthand */
import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'

// eslint-disable-next-line react/function-component-definition
const SearchFacetSelect = (props) => {
  const {
    name,
    isMulti = true,
    hideSelectedOptions = false,
    label,
    placeholder,
    options,
    selectedOptions,
    classNamePrefix = 'react-select',
    className = 'react-select__component',
    onChange,
  } = props

  const handleChange = (value, action) => {
    if (onChange) onChange({ name: action?.name, value: value })
  }

  return (
    <div className="facet-container">
      <div className="select-prompt">
        {label}
      </div>
      <div className="react-select-component-wrapper">
        <Select
          name={name}
          aria-label="Enter a search term"
          isMulti={isMulti}
          hideSelectedOptions={hideSelectedOptions}
          placeholder={placeholder}
          options={options}
          value={selectedOptions}
          classNamePrefix={classNamePrefix}
          className={className}
          onChange={handleChange}
          menuPortalTarget={document.body}
          styles={{
            option: (base, state) => ({
              ...base,
              backgroundColor: state.isSelected ? '#1b7392' : '#ffffff',
            }),
            placeholder: (base) => ({ ...base, fontSize: '0.8rem' }),
            menuPortal: (base) => ({
              ...base,
              zIndex: 7,
            }),
          }}
        />
      </div>
    </div>
  )
}

SearchFacetSelect.propTypes = {
  name: PropTypes.string.isRequired,
  isMulti: PropTypes.bool,
  hideSelectedOptions: PropTypes.bool,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  selectedOptions: PropTypes.array,
  classNamePrefix: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

export default SearchFacetSelect
