import consumer from './consumer'

export default function createSubscription(channel, received) {
  if (window.learnerPresent) {
    window.subscription = consumer.subscriptions.create(
      { channel },
      { received },
    )
  }
}
