import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import ArrowRightImg from 'images/icons/arrow-right.svg'
import routes from '../../services/routes'

const CategoryButton = function ({ category }) {
  return (
    <Link to={routes.categories.show(category.id)} className="category-button">
      <div className={`category-button__body category-button__body--${category.theme_variant}`}>
        <div className="category-button__title-mobile">
          <p className="subtitle-2 subtitle-2--white">{category.title}</p>
        </div>
        <div className="category-button__title-desktop">
          <h3 className="heading-3 heading-3--white">{category.title}</h3>
        </div>
        <div className="category-button__arrow">
          <img src={ArrowRightImg} alt="arrow" />
        </div>
      </div>
    </Link>
  )
}

CategoryButton.propTypes = {
  category: PropTypes.object.isRequired,
}

export default CategoryButton
