import {
  take, put, call,
} from 'redux-saga/effects'

import { CategoryActions, CategoryActionTypes } from '../../../actions'
import api from '../../../services/api'

export default function* loadCategory() {
  while (true) {
    const { id } = yield take(CategoryActionTypes.LOAD)

    try {
      const response = yield call(
        api.categories.show,
        id,
      )

      yield put(CategoryActions.loadSuccess(response.data))
    } catch (error) {
      yield put(CategoryActions.loadError(error.response.data.errors))
    }
  }
}
