export const LOAD = 'app/favorites/LOAD'
export const LOAD_SUCCESS = 'app/favorites/LOAD_SUCCESS'
export const LOAD_ERROR = 'app/favorites/LOAD_ERROR'

const load = () => ({ type: LOAD })
const loadSuccess = (favorites) => ({ type: LOAD_SUCCESS, favorites })
const loadError = (errors) => ({ type: LOAD_ERROR, errors })

export const FavoritesActions = {
  load,
  loadSuccess,
  loadError,
}

export const FavoritesActionTypes = {
  LOAD,
  LOAD_SUCCESS,
  LOAD_ERROR,
}
