import React from 'react'

import Testimonial from './Testimonial'

import maineTestimonials from './maineLiveAndLearn.json'
import southbendTestimonials from './southbendLiveAndLearn.json'

const TestimonialPage = function () {
  const instance = window.location.host.split('.')[0]
  // TODO: Return null if on the wrong instance?
  const testimonials = {
    maine: maineTestimonials,
    southbend: southbendTestimonials,
    'localhost:3000': southbendTestimonials, // For debugging on local
    'bendable-acceptance': southbendTestimonials, // For debugging on staging
  }

  const reorderedTestimonials = testimonials[instance].reverse()

  return (
    <div className="testimonials">
      <h2 className="testimonials__header">
        Live and Learn
      </h2>
      <div>
        {reorderedTestimonials.map((testimonial) => (
          <Testimonial
            testimonial={testimonial}
            instance={instance}
            key={testimonial.order}
          />
        ))}
      </div>
    </div>
  )
}

export default TestimonialPage
