import React from 'react'
import PropTypes from 'prop-types'

const PartOfCollection = function ({ collection }) {
  return (
    <a
      className="learning-object-card__part-of-a-collection-link"
      title={collection.plain_text_title}
      href={`/community_collections/${collection.id}`}
    >
      <div className="learning-object-card__part-of-a-collection-container">
        <div className="learning-object-card__part-of-a-collection-logo-container">
          <div className="learning-object-card__part-of-a-collection-logo--react" />
        </div>
      </div>
    </a>
  )
}

PartOfCollection.propTypes = {
  collection: PropTypes.object,
}

export default PartOfCollection
