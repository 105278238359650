import React from 'react'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'

import Routes from './AppRoutes'
import NavRoutes from './NavRoutes'
import { ScrollToTop } from '../shared'
import configureStore from './store'

// const shouldLog = false;

const App = function ({ initialState }) {
  // if (shouldLog) console.log('App.jsx initialState: ', initialState);

  return (
    <Provider store={configureStore(initialState)}>
      <Router>
        <ScrollToTop />
        <NavRoutes />
        <Routes />
      </Router>
    </Provider>
  )
}

App.propTypes = {
  initialState: PropTypes.object,
}

export default App
