import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'

import { GridLearningObjects } from '../GridComponents'
import { Header, Button } from '../shared'
import routes from '../../services/routes'

const MyLearning = function ({ learningObjects, learner }) {
  return (
    <div className="my-stuff">
      <div className="my-stuff__content">
        <div className="my-stuff__in-progress-header-container">
          <Header header="My learning" subheader="Resources that you&rsquo;ve found on Bendable" />
          { !isEmpty(learningObjects) && (
          <div className="my-stuff__in-progress-button-container">
            <Button
              classes="my-stuff__edit-button js-in-progress-edit-trigger"
              variant="hollow-gray"
              linkTarget="_self"
              content="Remove one or more resources"
            />
          </div>
          )}
        </div>
        {
          !isEmpty(learningObjects) && (
            <div className="content__grid">
              <GridLearningObjects learningObjects={learningObjects} learner={learner} bypassRedux />
            </div>
          )
        }
        {
          isEmpty(learningObjects) && (
            <>
              <div className="my-stuff__text-container">
                <p className="body-copy-2">
                  You haven&rsquo;t started any learning resources yet.
                </p>
              </div>
              <div className="my-stuff__text-container">
                <p className="body-copy-2">
                  Ready to find some learning that&rsquo;s right for you?
                  {' '}
                  <br />
                  {' '}
                  Go
                  {' '}
                  <b>Discover </b>
                  <i
                    className="my-stuff__inline-explore"
                    alt="Discover icon"
                  />
                </p>
              </div>
              <div className="my-stuff__spacer" />
              <div className="my-stuff__button-container">
                <Button
                  url={routes.explore.index()}
                  variant="hollow-gold"
                  linkTarget="_self"
                  content="Go to Discover"
                />
              </div>
            </>
          )
        }
      </div>
    </div>
  )
}

MyLearning.propTypes = {
  learningObjects: PropTypes.array,
  learner: PropTypes.object,
}

export default MyLearning
