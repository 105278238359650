import { CategoryActionTypes } from '../../../../actions'
import { LOADING_STATUSES } from '../../../shared'

export const defaultState = {
  status: LOADING_STATUSES.notLoaded,
  item: {},

}

export default function category(state = defaultState, action) {
  switch (action.type) {
    case CategoryActionTypes.LOAD:
      return { ...state, status: LOADING_STATUSES.loading }
    case CategoryActionTypes.LOAD_SUCCESS:
      return { ...state, item: action.category, status: LOADING_STATUSES.loaded }
    default:
      return state
  }
}
