import algoliasearch from 'algoliasearch'

const client = algoliasearch(
  process.env.ALGOLIA_APP,
  process.env.ALGOLIA_SEARCH_KEY,
)
const algoliaSearchClient = client.initIndex(
  process.env.ALGOLIA_LEARNING_OBJECTS_INDEX,
)

export {
  algoliaSearchClient,
}
