import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'

const FeaturedLibraries = function ({ featuredLibraries }) {
  if (isEmpty(featuredLibraries)) return null

  return (
    <div className="featured-libraries">
      { featuredLibraries.map((featuredLibrary) => (
        <div
          className="featured-libraries__container"
          key={featuredLibrary.headline}
        >
          <h1 className="featured-libraries__header">
            {featuredLibrary.headline}
          </h1>

          <div className="featured-libraries__library-info">
            <h2 className="featured-libraries__subheader">
              {featuredLibrary.subhead}
            </h2>
            <div className="featured-libraries__button-container">
              <button
                className="button featured-libraries__view-calendar-button"
                type="button"
                onClick={() => window.open(featuredLibrary.destination_url, '_blank')}
              >
                <span className="button__text">{featuredLibrary.button_label}</span>
              </button>
            </div>

          </div>
        </div>
      ))}
    </div>
  )
}

FeaturedLibraries.propTypes = {
  featuredLibraries: PropTypes.array,
}

export default FeaturedLibraries
