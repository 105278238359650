import React from 'react'
import ReactDOM from 'react-dom'

import { Profile, MyLearning } from '../MyStuff'
import { MainApp } from '../MainApp'
import Navbar from '../Navbar'
import { LearningObjectCard, PairedLearningObjectCards } from '../LearningObjects'

const COMPONENT_MAP = {
  Profile,
  MyLearning,
  MainApp,
  Navbar,
  LearningObjectCard,
  PairedLearningObjectCards,
}

document.addEventListener('DOMContentLoaded', () => {
  const elems = $('div[data-react-class]');
  [].forEach.call(elems, (elem) => {
    const component = COMPONENT_MAP[elem.getAttribute('data-react-class')]
    const props = $(elem).data('reactProps')
    const node = React.createElement(component, props)
    ReactDOM.render(node, elem)
  })
})
