import React from 'react'
import ReactMarkdown from 'react-markdown'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { isEmpty } from 'lodash'

import {
  NO_OF_LO_IMAGES,
  USER_STATUSES,
  FLASH_NOTIFICATIONS,
} from '../shared'

import InPersonLogo from '../../../assets/images/icons/building.svg'

const CommunityLearningPartnerCard = function ({
  learningObject,
  index,
  linkId,
  bypassRedux,
}) {
  const imageNumber = index % NO_OF_LO_IMAGES
  const learner = bypassRedux ? null : useSelector((state) => state.app.learner)

  const flashNotification = () => {
    if (learningObject.flash_notification) { return learningObject.flash_notification }

    if (!learningObject.prepaid_seats) { return false }

    if (isEmpty(learner)) {
      return FLASH_NOTIFICATIONS.loginForPaidResource
    }

    if (learner?.status === USER_STATUSES.expired) {
      return FLASH_NOTIFICATIONS.libraryCardRequiredForPaidResource
    }

    return null
  }

  return (
    <div
      className="learning-object-card community-learning-partner-card"
      data-learning-object-id={learningObject.id}
      id={linkId}
    >
      <a
        to={`/learning_objects/${learningObject.id}`}
        className={classnames(
          'learning-object-card__link',
          { 'js-trigger-flash-notification': flashNotification() },
        )}
        title={`${learningObject.title}`}
        type="LearningObject"
        data-targeted-notification={linkId}
        data-anchor-tag={linkId}
        data-flash-notification={flashNotification()}
        href={`/learning_objects/${learningObject.id}?bg_img=${imageNumber}`}
      >
        <div className="community-learning-partner-card__text">
          <div className="learning-object-card__title">
            <h4 className="heading-4">
              <ReactMarkdown children={learningObject.title} />
            </h4>
          </div>
        </div>
        <div className="learning-object-card__footer-container">
          <div className="community-learning-partner-card__subtitle">
            <img src={InPersonLogo} alt="" />
            <div className="community-learning-partner-card__subtitle-text indicator__icon-text">
              Community Learning Partner
            </div>
          </div>
        </div>
      </a>
    </div>
  )
}

CommunityLearningPartnerCard.propTypes = {
  learningObject: PropTypes.object.isRequired,
  index: PropTypes.number,
  linkId: PropTypes.string,
  bypassRedux: PropTypes.bool,
}

export default CommunityLearningPartnerCard
