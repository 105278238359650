import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useLocation, Link } from 'react-router-dom'

import routes from '../../services/routes'

export const NavLink = function ({
  route, withRouter, children, classNames,
}) {
  if (withRouter) {
    return (
      <Link to={route} className={classNames}>
        {children}
      </Link>
    )
  }

  return (
    <a href={route} className={classNames}>
      {children}
    </a>
  )
}

NavLink.propTypes = {
  route: PropTypes.string.isRequired,
  withRouter: PropTypes.bool,
  children: PropTypes.node,
  classNames: PropTypes.string,
}

const NavButton = function ({
  route,
  title,
  iconImg,
  activityMonitor,
  displayNotification,
  iconClassName,
  hideSpacer,
  withRouter,
}) {
  const location = useLocation()

  const onPage = (path) => path.match(location.pathname)
    && location.pathname !== routes.root()

  return (
    <>
      {
        !hideSpacer && (<div className="navbar__button-spacer" />)
      }
      <NavLink
        route={route}
        title={title}
        classNames={
          classnames(
            'navbar__item',
            { 'navbar__item--active': onPage(route) },
          )
        }
        withRouter={withRouter}
      >
        {
          activityMonitor && (
            <div className={
              classnames(
                'navbar__activity js-activity-monitor',
                { 'navbar__activity--active': displayNotification },
              )
            }
            >
              <div className="navbar__activity-monitor" />
            </div>
          )
        }
        <img
          className={classnames('navbar__icon', { [iconClassName]: iconClassName })}
          src={iconImg}
          alt=" "
        />
        <div className="navbar__item-text">
          <div
            className={
              classnames(
                'nav-bar-items nav-bar-items--grows-on-desktop',
                'nav-bar-items--active',
              )
            }
          >
            { title }
          </div>
        </div>
      </NavLink>
    </>
  )
}

NavButton.propTypes = {
  route: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  iconImg: PropTypes.string.isRequired,
  activityMonitor: PropTypes.bool,
  displayNotification: PropTypes.bool,
  iconClassName: PropTypes.string,
  hideSpacer: PropTypes.bool,
  withRouter: PropTypes.bool,
}

export default NavButton
