import Cleave from 'cleave.js'

document.addEventListener('DOMContentLoaded', () => {
  document
    .querySelectorAll('input.js-phone-number')
    .forEach((el) => {
      /* eslint-disable no-new */
      new Cleave(el, {
        numericOnly: true,
        blocks: [3, 3, 4],
      })
      /* eslint-enable no-new */
    })
})
