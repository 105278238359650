/* eslint-disable react/jsx-fragments */
/* eslint-disable no-underscore-dangle */
/* eslint-disable arrow-body-style */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { matchSorter } from 'match-sorter'
import Autosuggest from 'react-autosuggest'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { map } from 'lodash'

import callHubApi, { getSetting } from '../../services/hubApi';
import { useQuery } from '../../services/hooks';
import {
  Header,
  SEARCH_FIRST_PAGE,
  useSessionStorage,
  buildAlgoliaFacetArg,
} from '../shared'
import routes from '../../services/routes'
import {
  TagsActions,
  TopicsActions,
  ProvidersActions,
  SearchActions,
} from '../../actions'
import SearchFacets from './SearchFacets'

// const shouldLog = false;

const SearchBar = function (props) {
  const { navigateOnly = false } = props;
  const query = useQuery(['search_term']);

  // The search term is being retrieved from the querystring, not Redux anymore.
  // Once testing passes these lines can be removed.
  // const storedSearchTerm = useSelector((state) => state.local.search.searchTerm);
  // if (shouldLog) console.log('SearchBar loading; storedSearchTerm: ', storedSearchTerm);
  // const [searchTerm, setSearchTerm] = useState(storedSearchTerm);

  const [searchTerm, setSearchTerm] = useState(query?.searchTerm);
  const [suggestedList, setSuggestedList] = useState([]);
  const [topicOptions, setTopicOptions] = useState([]);
  const [providerOptions, setProviderOptions] = useState([]);
  const [shouldShowFacetedSearch, setShouldShowFacetedSearch] = useState(false);

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const searchList = useSelector((state) => state.app.tags.items)
  const topicsList = useSelector((state) => state.app.topics.items)
  const providersList = useSelector((state) => state.app.providers.items)
  // const shouldShowSearchFacets = useSelector((state) => state.local.search.shouldShowSearchFacets);
  // const searchFacets = useSelector((state) => state.local.search.searchFacets);

  const [shouldShowSearchFacets, setShouldShowSearchFacets] = useSessionStorage('shouldShowSearchFacets', false)
  // const [sessionStorageSearchFacets, setSessionStorageSearchFacets] = useSessionStorage('searchFacets', {});
  const [localSearchFacets, setLocalSearchFacets] = useState(null);
  const [localSearchZipcodeData, setLocalSearchZipcodeData] = useState({});
  const [lastZipcodeFetched, setLastZipcodeFetched] = useState();
  // if (shouldLog) console.log('SearchBar loading; localSearchFacets: ', localSearchFacets);

  const doSearch = () => {
    // if (shouldLog) console.log('SearchBar.doSearch() localSearchFacets: ', localSearchFacets);
    const facetArg = buildAlgoliaFacetArg(localSearchFacets)
    // if (shouldLog) console.log('SearchBar.doSearch() facetArg: ', facetArg);

    dispatch(SearchActions.submit(searchTerm))
    if (navigateOnly) return

    // if (shouldLog) console.log('SearchBar.doSearch() about to load page: ', SEARCH_FIRST_PAGE);
    dispatch(SearchActions.search(
      searchTerm,
      SEARCH_FIRST_PAGE,
      facetArg,
      localSearchZipcodeData,
    ));
  };

  // dynamically get setting 'search.zipcode_search' to determine if we should show the Zipcode Search panel
  useEffect(() => {
    const asyncSafe = async () => {
      try {
        const setting = await getSetting('search.faceted_search');
        setShouldShowFacetedSearch(setting === 'enabled');
      } catch (err) {
        setShouldShowFacetedSearch(false);
      }
    };
    asyncSafe();
  }, []);

  useEffect(() => {
    if (localSearchFacets !== null) {
      dispatch(SearchActions.setSearchFacets(localSearchFacets))
      doSearch()
    }
  }, [localSearchFacets])

  useEffect(() => {
    if (localSearchFacets !== null) {
      dispatch(SearchActions.setSearchZipcodeData(localSearchZipcodeData));
      // doSearch(); // trigger search by default or not?
    }
  }, [localSearchZipcodeData]);

  // load searchFacets from localStorage
  useEffect(() => {
    let facetData;
    try {
      const item = window.sessionStorage.getItem('searchFacets')
      // if (shouldLog) console.log('useSessionStorage() item: ', item);
      // if (shouldLog) console.log('useSessionStorage() initialValue: ', {});
      facetData = item ? JSON.parse(item) : {}
    } catch (err) {
      facetData = {};
    }
    setLocalSearchFacets(() => (facetData));
  }, []);

  // load searchZipcodeData from localStorage
  useEffect(() => {
    let searchZipcodeData;
    try {
      const item = window.sessionStorage.getItem('searchZipcodeData');
      // if (shouldLog) console.log('useSessionStorage() item: ', item);
      // if (shouldLog) console.log('useSessionStorage() initialValue: ', {});
      searchZipcodeData = item
        ? JSON.parse(item)
        : {
          zipcode: '',
          latitude: 0,
          longitude: 0,
          radius: 5, // radius index 5
        };
    } catch (err) {
      searchZipcodeData = {
        zipcode: '',
        latitude: 0,
        longitude: 0,
        radius: 5, // radius index 5
      };
    }
    setLocalSearchZipcodeData(() => (searchZipcodeData));
  }, []);

  useEffect(() => {
    dispatch(TagsActions.load());
  }, []);

  useEffect(() => {
    if (shouldShowFacetedSearch) {
      dispatch(TopicsActions.load());
    }
  }, [shouldShowFacetedSearch]);

  useEffect(() => {
    if (shouldShowFacetedSearch) {
      dispatch(ProvidersActions.load());
    }
  }, [shouldShowFacetedSearch]);

  // useEffect(() => {
  //   setSearchTerm(storedSearchTerm);
  // }, [setSearchTerm, storedSearchTerm]);

  useEffect(() => {
    setTopicOptions(() => {
      return map(topicsList, (topicData) => ({ label: topicData?._pg__name, value: topicData?._pg__name }))
    })
  }, [topicsList])

  useEffect(() => {
    setProviderOptions(() => {
      return map(
        providersList,
        (providerData) => ({ label: providerData?._pg__name, value: providerData?._pg__name }),
      )
    })
  }, [providersList])

  const handleInput = (e, { newValue }) => {
    setSearchTerm(newValue)
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      navigate(routes.explore.search(searchTerm))
      doSearch()
    }
  }

  const handleToggleSearchFacets = () => {
    // dispatch(SearchActions.showSearchFacets(!shouldShowSearchFacets));
    setShouldShowSearchFacets(!shouldShowSearchFacets)
  }

  const handleAccessibleToggleSearchFacets = (e) => {
    // Accessibility function that toggles the search filters
    // when 'space' or 'return' keys are pressed
    if (e.which === 13 || e.which === 32) {
      e.preventDefault()
      handleToggleSearchFacets()
    }
  }

  const handleFacetChange = (action) => {
    // if (shouldLog) console.log('SearchBar.handleFacetChange() action: ', action);
    // if (shouldLog) console.log('SearchBar.handleFacetChange() localSearchFacets: ', localSearchFacets);

    const newLocalState = { ...localSearchFacets }
    newLocalState[action?.name] = action?.value
    // if (shouldLog) console.log('SearchBar.handleFacetChange() about to set newLocalState: ', newLocalState);

    setLocalSearchFacets(newLocalState)
    // use sessionStorage to survive Redux initialization on page loads
    window.sessionStorage.setItem('searchFacets', JSON.stringify(newLocalState))
    // dispatch(SearchActions.setSearchFacet(action?.name, action?.value));
  }

  const handleSearchFiltersReset = () => {
    // if (shouldLog) console.log('SearchBar.handleSearchFiltersReset() called...');
    // replace localSearchFacets, don't merge onto it
    setLocalSearchFacets(() => ({}));
    setLocalSearchZipcodeData(() => ({}));
    // use sessionStorage to survive Redux initialization on page loads
    window.sessionStorage.setItem('searchFacets', JSON.stringify({}));
    window.sessionStorage.setItem('searchZipcodeData', JSON.stringify({}));
  };

  const handleSearchRefresh = () => {
    doSearch();
  };

  const handleZipcodeDataChange = (newData) => {
    // if (shouldLog) console.log('SearchBar.handleZipcodeDataChange() newData: ', newData);

    const getLocationData = async () => {
      if (!newData?.zipcode || (newData.zipcode.length !== 5)) { // zipcode, city, lat and lon are now invalid
        const newState = {
          ...newData,
          zipcode: newData?.zipcode,
          city: '',
          latitude: 0,
          longitude: 0,
        }
        window.sessionStorage.setItem('searchZipcodeData', JSON.stringify(newState)); // zipcode is not ready, no api
        setLocalSearchZipcodeData(() => (newState));
        setLastZipcodeFetched('');
        return;
      }

      let apiResponse;
      if (lastZipcodeFetched !== newData.zipcode) {
        setLastZipcodeFetched(newData.zipcode);
        try {
          apiResponse = await callHubApi(
            'getZipcode',
            { zipcode: newData.zipcode },
            {},
          );
          // if (shouldLog) console.log('SearchZipcode.getLocationData() apiResponse: ', apiResponse);
        } catch (err) {
          apiResponse = null;
        }
      }

      const newState = {
        ...newData,
      }
      if (apiResponse) {
        const apiData = apiResponse?.data?.payload || {};
        newState.city = apiData.city;
        newState.latitude = apiData.latitude;
        newState.longitude = apiData.longitude;
      }
      // if (shouldLog) console.log('SearchZipcode.getLocationData() newState: ', newState);
      window.sessionStorage.setItem('searchZipcodeData', JSON.stringify(newState));
      setLocalSearchZipcodeData(() => (newState));
    };
    getLocationData();
  };

  return (
    <React.Fragment>
      <div className="search-header">
        <Header
          big
          header="Discover"
          subheader="Whether you want to search all of Bendable, explore a particular interest area or browse through
          popular topics, we make it easy to find the learning that’s right for you."
        />
      </div>
      <div className="search">
        <div className="search__container">
          <Autosuggest
            suggestions={suggestedList}
            onSuggestionsFetchRequested={({ value }) => setSuggestedList(matchSorter(searchList, value).slice(0, 5))}
            onSuggestionsClearRequested={() => setSuggestedList([])}
            getSuggestionValue={(suggestion) => suggestion}
            renderSuggestion={(suggestion) => <div className="search__suggestion">{ suggestion }</div>}
            inputProps={{
              value: searchTerm || '',
              onChange: handleInput,
              onKeyPress: handleKeyPress,
              placeholder: 'Type here',
              name: 'search_term',
              className: 'form__input-field search__input',
            }}
          />
          <Link
            onClick={doSearch}
            to={routes.explore.search(searchTerm)}
            className="button button--solid-dark-blue button--end-cap search__button"
          >
            <span className="button__text">Search</span>
            <span className="lg_button__icon button__icon--magnifying-glass" />
          </Link>
          { shouldShowFacetedSearch
            ? (
              <div
                role="button"
                aria-label="Toggle Search Filters"
                tabIndex={0}
                className="button__icon--filter filter-icon"
                onClick={handleToggleSearchFacets}
                onKeyPress={handleAccessibleToggleSearchFacets}
              />
            )
            : null}
        </div>
      </div>
      { shouldShowFacetedSearch ? (
        <SearchFacets
          topicOptions={topicOptions}
          providerOptions={providerOptions}
          visible={shouldShowSearchFacets}
          facetData={localSearchFacets}
          zipcodeData={localSearchZipcodeData}
          onChange={handleFacetChange}
          onZipcodeDataChange={handleZipcodeDataChange}
          onReset={handleSearchFiltersReset}
          onRefresh={handleSearchRefresh}
        />
      )
        : null}
    </React.Fragment>
  )
}

SearchBar.propTypes = {
  navigateOnly: PropTypes.bool,
}

export default SearchBar
