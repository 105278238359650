import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, A11y } from 'swiper'
import PropTypes from 'prop-types'
import HeroCarouselSlide, { slideProperties } from './HeroCarouselSlide'

const HeroCarousel = function ({ slides }) {
  return (
    <div className="hero-carousel">
      <Swiper
        modules={[Pagination, A11y]}
        pagination={{ clickable: true }}
        a11y
      >
        {slides.map(({
          header, subheader, image, id,
        }) => (
          <SwiperSlide key={id}>
            <HeroCarouselSlide
              header={header}
              subheader={subheader}
              image={image}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

HeroCarousel.propTypes = {
  slides: PropTypes.arrayOf(PropTypes.shape(slideProperties)),
}

export default HeroCarousel
