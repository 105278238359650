import { SearchActionTypes } from '../../../../actions'
import { LOADING_STATUSES, SEARCH_FIRST_PAGE } from '../../../shared'

// const shouldLog = false;

export const defaultState = {
  hits: [],
  finalPageReached: false,
  resultLength: 0,
  status: LOADING_STATUSES.notLoaded,
  searchTerm: '',
  page: SEARCH_FIRST_PAGE,
  shouldShowSearchFacets: false,
  searchFacets: {},
  searchZipcodeData: {},
};

export default function community(state = defaultState, action) {
  // if (shouldLog) console.log('MainApp reducers search.community() action: ', action);
  switch (action.type) {
    case SearchActionTypes.SEARCH:
      // NOTE: action has usused "searchTerm" and "page" params that are used in the saga with the Algolia client
      // if (shouldLog) console.log('MainApp reducers search.community() SearchActionTypes.SEARCH; action: ', action);
      return {
        ...state,
        status: LOADING_STATUSES.loading,
      }
    case SearchActionTypes.SEARCH_SUCCESS:
      return {
        ...state,
        hits: [
          ...state.hits,
          ...action.hits,
        ],
        finalPageReached: action.finalPageReached,
        resultLength: action.resultLength,
        status: LOADING_STATUSES.loaded,
      }
    case SearchActionTypes.SUBMIT:
      return {
        ...state,
        searchTerm: action.searchTerm,
        hits: [],
        finalPageReached: false,
        resultLength: 0,
        page: SEARCH_FIRST_PAGE,
      }
    case SearchActionTypes.INCREMENT_PAGE:
      return {
        ...state,
        page: state.page + 1,
        status: state.finalPageReached ? LOADING_STATUSES.loaded : LOADING_STATUSES.loading,
      }
    case SearchActionTypes.SHOW_SEARCH_FACETS:
      return {
        ...state,
        shouldShowSearchFacets: action.shouldShowSearchFacets,
      }
    case SearchActionTypes.SET_SEARCH_FACETS: {
      // if (shouldLog) console.log('search.community() SET_SEARCH_FACETS before update; state: ', state);
      // if (shouldLog) console.log('search.community() SET_SEARCH_FACETS before update; action.value: ', action.value);
      return {
        ...state,
        searchFacets: action.value,
      }
    }
    case SearchActionTypes.SET_SEARCH_ZIPCODE_DATA:
      return {
        ...state,
        searchZipcodeData: action.value,
      };
    default:
      return state
  }
}
