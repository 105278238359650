import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import ReactGA from 'react-ga'
import { GOOGLE_ANALYTICS_TRACKING_ID } from '../components/shared'

const usePageTracking = () => {
  const location = useLocation()
  const [initialized, setInitialized] = useState(false)

  useEffect(() => {
    if (
      process.env.NODE_ENV !== 'development'
        && process.env.RAILS_ENV !== 'acceptance') {
      ReactGA.initialize(GOOGLE_ANALYTICS_TRACKING_ID)
      setInitialized(true)
    }
  }, [])

  useEffect(() => {
    if (initialized) {
      ReactGA.pageview(location.pathname + location.search)
    }
  }, [initialized, location])
}

export default usePageTracking
