const SEARCH = 'local/search/SEARCH';
const SEARCH_SUCCESS = 'local/search/SEARCH_SUCCESS';
const SEARCH_ERROR = 'local/search/SEARCH_ERROR';
const SUBMIT = 'local/search/SUBMIT';
const INCREMENT_PAGE = 'local/search/INCREMENT_PAGE';
const SHOW_SEARCH_FACETS = 'local/search/SHOW_SEARCH_FACETS';
const SET_SEARCH_FACETS = 'local/search/SET_SEARCH_FACETS';
const SET_SEARCH_ZIPCODE_DATA = 'local/search/SET_SEARCH_ZIPCODE_DATA';

const search = (searchTerm, page, searchFacets = [], searchZipcodeData = {}) => ({
  type: SEARCH,
  searchTerm,
  page,
  searchFacets,
  searchZipcodeData,
});

const searchSuccess = (hits, finalPageReached, resultLength) => (
  {
    type: SEARCH_SUCCESS,
    hits,
    finalPageReached,
    resultLength,
  }
);
const searchError = (errors) => ({ type: SEARCH_ERROR, errors });
const submit = (searchTerm) => ({ type: SUBMIT, searchTerm });
const incrementPage = () => ({ type: INCREMENT_PAGE });
const showSearchFacets = (shouldShowSearchFacets) => ({ type: SHOW_SEARCH_FACETS, shouldShowSearchFacets });
const setSearchFacets = (value) => ({ type: SET_SEARCH_FACETS, value });
const setSearchZipcodeData = (value) => ({ type: SET_SEARCH_ZIPCODE_DATA, value });

export const SearchActions = {
  search,
  searchSuccess,
  searchError,
  submit,
  incrementPage,
  showSearchFacets,
  setSearchFacets,
  setSearchZipcodeData,
};

export const SearchActionTypes = {
  SEARCH,
  SEARCH_SUCCESS,
  SEARCH_ERROR,
  SUBMIT,
  INCREMENT_PAGE,
  SHOW_SEARCH_FACETS,
  SET_SEARCH_FACETS,
  SET_SEARCH_ZIPCODE_DATA,
};
