/* eslint-disable max-len */
module.exports.constants = {
  routes: {
    apiFlashMessagesPath: () => '/api/flash_messages',
    learningObjectStatePath: (learningObjectId) => `/learning_objects/${learningObjectId}/learning_object_state`,
    apiUserValidatePath: () => '/api/user/validate',
  },
  email_regex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
}
/* eslint-enable max-len */
