import React from 'react'
import ReactMarkdown from 'react-markdown'
import PropTypes from 'prop-types'

import { LinkRenderer } from '../shared'

import HandoffButton from './HandoffButton'

const CommunityLearningPartnerHandoffPage = function ({ learningObject }) {
  return (
    <div className="branded-handoff">
      <div className="branded-handoff__image branded-handoff__image--bg-image-community-learning-partner" />
      <h1 className="branded-handoff__header heading-1 heading-1--grows-on-desktop">{learningObject.title}</h1>

      <div className="branded-handoff__text-block">
        <div className="body-copy-2">
          <ReactMarkdown children={learningObject.bendable_description} />
        </div>
      </div>

      <div className="branded-handoff__button-container">
        <HandoffButton
          learningObject={learningObject}
          learner={learningObject.learner}
          loState={learningObject.state}
          url={learningObject.url}
        />
      </div>
      <div className="branded-handoff__text-block">
        <h4 className="branded-handoff__subhead">What to expect</h4>
        {
                    learningObject.handoff_content_short_description && (
                    <ReactMarkdown
                      components={{ a: LinkRenderer }}
                      className="bendable-markdown"
                      children={learningObject.handoff_content_short_description}
                    />
                    )
                }
      </div>
    </div>
  )
}

CommunityLearningPartnerHandoffPage.propTypes = {
  learningObject: PropTypes.shape({
    title: PropTypes.string,
    bendable_description: PropTypes.string,
    learner: PropTypes.string,
    state: PropTypes.string,
    url: PropTypes.string,
    handoff_content_short_description: PropTypes.string,
  }),
}

export default CommunityLearningPartnerHandoffPage
