const LOAD = 'app/faqSections/LOAD'
const LOAD_SUCCESS = 'app/faqSections/LOAD_SUCCESS'
const LOAD_ERROR = 'app/faqSections/LOAD_ERROR'

const load = () => ({ type: LOAD })
const loadSuccess = (sections) => ({ type: LOAD_SUCCESS, sections })
const loadError = (errors) => ({ type: LOAD_ERROR, errors })

export const FaqSectionsActions = {
  load,
  loadSuccess,
  loadError,
}

export const FaqSectionsActionTypes = {
  LOAD,
  LOAD_SUCCESS,
  LOAD_ERROR,
}
