import { TopicsActionTypes } from '../../../../actions'
import { LOADING_STATUSES } from '../../../shared'

// const shouldLog = false;

export const defaultState = {
  items: [],
  status: LOADING_STATUSES.notLoaded,
}

export default function topics(state = defaultState, action) {
  // if (shouldLog) console.log('topics reducers; state, action: ', state, action);

  switch (action.type) {
    case TopicsActionTypes.LOAD:
      return { ...state, status: LOADING_STATUSES.loading }
    case TopicsActionTypes.LOAD_SUCCESS:
      return { ...state, items: action.topics, status: LOADING_STATUSES.loaded }
    default:
      return state
  }
}
