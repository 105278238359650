import { combineReducers } from 'redux'

import boards from './app/boards'
import favorites from './app/favorites'
import favoriteResources from './app/favoriteResources'
import categories from './app/categories'
import homepage from './app/homepage'
import faq from './app/faq'
import learner from './app/learner'
import tags from './app/tags'
import topics from './app/topics'
import providers from './app/providers'
import env from './app/env'
import exploreSections from './app/exploreSections'

import category from './local/category'
import board from './local/board'
import careerCollection from './local/careerCollection'
import learningObject from './local/learningObject'
import learningObjects from './local/learningObjects'
import search from './local/search'

export default combineReducers({
  app: combineReducers({
    favorites,
    favoriteResources,
    faq,
    categories,
    boards,
    exploreSections,
    env,
    homepage,
    learner,
    tags,
    topics,
    providers,
  }),
  local: combineReducers({
    careerCollection,
    category,
    board,
    learningObject,
    learningObjects,
    search,
  }),
})
