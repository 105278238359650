import {
  take, put, call,
} from 'redux-saga/effects'

import { BoardsActions, BoardsActionTypes } from '../actions'
import api from '../services/api'

export default function* loadBoards() {
  while (true) {
    yield take(BoardsActionTypes.LOAD)

    try {
      const response = yield call(
        api.boards.index,
      )

      yield put(BoardsActions.loadSuccess(response.data))
    } catch (error) {
      yield put(BoardsActions.loadError(error.response.data.errors))
    }
  }
}
