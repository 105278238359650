import React from 'react'
import PropTypes from 'prop-types'
import { LearningObjectCard } from '../LearningObjects'
import { CommunityCollectionCard, CareerCollectionCard } from '../Collection'

function GenericCard({ index, loggedIn, resource }) {
  if (resource.type === 'CommunityCollection') {
    return (
      <CommunityCollectionCard collection={resource} index={index} loggedIn={loggedIn} />
    )
  } if (resource.type === 'LearningObject') {
    return (
      <LearningObjectCard learningObject={resource} index={index} loggedIn={loggedIn} />
    )
  } if (resource.type === 'CareerCollection') {
    return (
      <CareerCollectionCard collection={resource} loggedIn={loggedIn} />
    )
  }

  return null
}

GenericCard.propTypes = {
  index: PropTypes.number,
  loggedIn: PropTypes.bool,
  resource: PropTypes.shape({
    type: PropTypes.string,
  }),
}

export default GenericCard
