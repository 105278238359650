import React from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import { Link } from 'react-router-dom'

import {
  findTheme,
  usePageTitle,
} from '../shared'

const BoardTile = function ({ board }) {
  const themeNumber = findTheme(board.ordered_number)

  usePageTitle('Boards')

  return (
    <Link
      to={`/boards/${board.id}`}
      className={`board-button__body board-button__body--theme-${themeNumber}`}
    >
      <div className="board-button__header">
        <h3 className="board-button__title">
          {board.organization}
        </h3>
      </div>
      <div className={`board-button__footer board-button__footer--theme-${themeNumber}`}>
        <ReactMarkdown children={board.program} className="board-button__footer-text" />
      </div>
    </Link>
  )
}

BoardTile.propTypes = {
  board: PropTypes.object.isRequired,
}

const Boards = function ({ boards }) {
  return (
    <div className="boards__container">
      <div className="content">
        <section className="content-section">
          <div className="boards__grid">
            {
              boards.map((c) => <BoardTile key={c.id} board={c} />)
            }
          </div>
        </section>
      </div>
    </div>
  )
}

Boards.propTypes = {
  boards: PropTypes.array.isRequired,
}

export default Boards
