const routes = {
  boards: {
    index: () => '/boards',
    show: (id) => `/boards/${id}`,
  },
  careerCollections: {
    show: (id) => `/career_collections/${id}`,
  },
  categories: {
    show: (id) => `/categories/${id}`,
  },
  communityCollections: {
    show: (id) => `/community_collections/${id}`,
  },
  explore: {
    index: () => '/explore',
    search: (searchTerm) => `/explore/search${searchTerm ? `?search_term=${searchTerm}` : ''}`,
  },
  learningObjects: {
    show: (id) => `/learning_objects/${id}`,
    start: (id) => `/learning_objects/${id}/start`,
  },
  logOut: () => '/users/sign_out',
  more: () => '/more',
  myAccount: {
    edit: () => '/my_account/edit',
    update: () => '/my_account',
  },
  myStuff: {
    index: () => '/my_stuff',
  },
  root: () => '/',
  sessions: {
    new: () => '/users/sign_in',
  },
  signUp: () => '/users/sign_up',
  testimonials: {
    index: () => '/testimonials',
  },
}

export default routes
