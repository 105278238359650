const { constants } = require('../constants')

$(() => {
  const input = $('.js-email-validator')
  const submitButton = $('.form__submit-button')
  const errorMessage = $('.form__field-error')

  input.keyup((e) => {
    const email = e.target.value
    const validEmail = !!constants.email_regex.test(String(email).toLowerCase())

    submitButton.toggleClass('form__submit-button--disabled', !validEmail)
    input.toggleClass('form__input-field--error', !validEmail)
    errorMessage.toggleClass('form__field-error--hidden', validEmail)
  })
})
