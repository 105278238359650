import React from 'react'
import {
  Routes,
  Route,
} from 'react-router-dom'

import routes from '../../services/routes'
import Navbar, { Back } from '../Navbar'

const NavRoutes = function () {
  return (
    <Routes>
      <Route
        path={routes.careerCollections.show(':id')}
        element={<Back />}
      />
      <Route
        path={routes.learningObjects.show(':id') || routes.careerCollections.show(':id')}
        element={<Back />}
      />
      <Route
        path="*"
        element={<Navbar />}
      />
    </Routes>
  )
}

export default NavRoutes
