import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'

import { CommunityCollectionCard, CareerCollectionCard } from '../Collection'

const GridCollections = function ({
  collections, learner, bypassRedux, collectionCardClassName,
}) {
  return (
    <div className="grid grid--collections">
      {
      collections.map((collection, i) => {
        if (collection.type === 'CommunityCollection') {
          return (
            <CommunityCollectionCard
              key={collection.id}
              collection={collection}
              index={i}
              loggedIn={!isEmpty(learner)}
              bypassRedux={bypassRedux}
              className={collectionCardClassName}
            />
          )
        }
        return (
          <CareerCollectionCard
            key={collection.id}
            collection={collection}
            loggedIn={!isEmpty(learner)}
            bypassRedux={bypassRedux}
            className={collectionCardClassName}
          />
        )
      })
    }
    </div>
  )
}

GridCollections.propTypes = {
  collections: PropTypes.array.isRequired,
  learner: PropTypes.object,
  bypassRedux: PropTypes.bool,
  collectionCardClassName: PropTypes.string,
}

export default GridCollections
