import {
  take, put, call,
} from 'redux-saga/effects'

import { LearningObjectActions, LearningObjectActionTypes } from '../actions'
import api from '../services/api'

export default function* loadLearningObject() {
  while (true) {
    const { id } = yield take(LearningObjectActionTypes.LOAD)

    try {
      const response = yield call(
        api.learningObjects.show,
        id,
      )

      yield put(LearningObjectActions.loadSuccess(response.data))
    } catch (error) {
      yield put(LearningObjectActions.loadError(error.response.data.errors))
    }
  }
}
