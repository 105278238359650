import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import FeaturedBoardGroup from './FeaturedBoardGroup'

const FeaturedBoardGroups = function ({ featuredBoardGroups }) {
  if (isEmpty(featuredBoardGroups)) return null

  return (
    <div className="featured-board-groups">
      {featuredBoardGroups.map((featuredBoardGroup) => (
        <FeaturedBoardGroup
          featuredBoardGroup={featuredBoardGroup}
          key={featuredBoardGroup.id}
        />
      ))}
      <hr className="featured-board-groups__hr" />
    </div>
  )
}

FeaturedBoardGroups.propTypes = {
  featuredBoardGroups: PropTypes.array,
}

export default FeaturedBoardGroups
