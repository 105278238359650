import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'

import SearchBar from './SearchBar'
import ResultsHeader from './ResultsHeader'
import Results from './Results'
import {
  FavoritesActions,
} from '../../actions'
import {
  LOADING_STATUSES,
  SEARCH_FIRST_PAGE,
  useSessionStorage,
} from '../shared'

// const shouldLog = false;

const SearchPage = function () {
  const dispatch = useDispatch()
  const learner = useSelector((state) => state.app.learner)

  // eslint-disable-next-line no-unused-vars
  const [sessionStorageSearchFacets, setSessionStorageSearchFacets] = useSessionStorage('searchFacets', {})

  useEffect(() => {
    if (!isEmpty(learner)) {
      dispatch(FavoritesActions.load())
    }
  }, [])

  return (
    <div className="searches">
      <div className="searches__content">
        <div className="searches__input-container">
          <SearchBar />
          <ResultsHeader />
          <Results />
        </div>
      </div>
    </div>
  )
}

export default SearchPage
