import React from 'react'
import PropTypes from 'prop-types'

import GridCollections from './GridCollections'
import GridLearningObjects from './GridLearningObjects'
import GridPairedLearningObjects from './GridPairedLearningObjects'

const BASE_NUM_TO_DISPLAY = 3

const GridChooser = function ({
  items,
  type,
  learner,
}) {
  switch (type) {
    case 'collection':
      return (
        <GridCollections
          collections={items}
          learner={learner}
          bypassRedux
          hideWrapperCss
        />
      )
    case 'learning-object':
      return (
        <GridLearningObjects
          learningObjects={items}
          learner={learner}
          bypassRedux
        />
      )
    case 'paired-learning-object':
      return (
        <GridPairedLearningObjects
          pairs={items}
          learner={learner}
        />
      )
    default:
      return null
  }
}

GridChooser.propTypes = {
  items: PropTypes.array.isRequired,
  type: PropTypes.string,
  learner: PropTypes.object,
}

export default GridChooser
