import React from 'react'
import PropTypes from 'prop-types'

const CollectionCardResourceCount = function ({ resourceCount }) {
  return (
    <div className="collection-card__resources-container">
      <div className="collection-card__resources-shadow" />
      <div className="collection-card__resources">
        {`${resourceCount}`}
      </div>
    </div>
  )
}

CollectionCardResourceCount.propTypes = {
  resourceCount: PropTypes.number,
}

export default CollectionCardResourceCount
