const api = {
  post: (url, data = {}) => {
    const headers = {
      'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content,
    }
    return $.post({ url, data, headers })
  },
}

module.exports = api
