import {
  take, put, call,
} from 'redux-saga/effects'

import { FavoriteResourcesActions, FavoriteResourcesActionTypes } from '../actions'
import api from '../services/api'

export default function* loadFavoriteResources() {
  while (true) {
    yield take(FavoriteResourcesActionTypes.LOAD)

    try {
      const response = yield call(
        api.favoriteResources.index,
      )

      yield put(FavoriteResourcesActions.loadSuccess(response.data))
    } catch (error) {
      yield put(FavoriteResourcesActions.loadError(error.response.data.errors))
    }
  }
}
