import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { isEmpty } from 'lodash'

import { CareerCollectionActions, FavoritesActions } from '../../../actions'
import { Favorite, selectFavorite, OBJECT_TYPES } from '../../shared'
import CareerCollectionSection from './CareerCollectionSection'

const CareerCollection = function () {
  const dispatch = useDispatch()
  const { id } = useParams()
  const collection = useSelector((state) => state.local.careerCollection.item)
  const learner = useSelector((state) => state.app.learner)
  const myFavorite = useSelector(selectFavorite(collection, OBJECT_TYPES.careerCollection))

  useEffect(() => {
    dispatch(FavoritesActions.load())
  }, [
    dispatch,
  ])

  useEffect(() => {
    if (isEmpty(collection) || collection.id?.toString() !== id) {
      dispatch(CareerCollectionActions.load(id))
    }

    return function cleanUp() {
      dispatch(CareerCollectionActions.clear())
    }
  }, [dispatch, CareerCollectionActions.load, collection])

  if (isEmpty(collection)) return null

  if (collection.title) {
    document.title = `Bendable: Career Collection: ${collection.title}`
  }

  return (
    <div className="career-collection__container">
      <article className="career-collection">
        <div className="career-collection__portrait">
          {/* Removed "overlaid-image" class, compared to pre-existing slim html */}

          <div
            className="overlaid-image collection__image"
            style={{ backgroundImage: `url('${collection.image_url}')` }}
          >
            <div className="career-collection__credits-container">
              <div className="career-collection__credits">
                <div className="subtitle-2 subtitle-2--white">
                  Career Collection
                </div>
                <h3 className="subtitle-1 subtitle-1--white">
                  {collection.industry}
                </h3>
              </div>
            </div>
          </div>
        </div>

        {/* Career Collection header backgrounds are always blue */}
        <div className="career-collection__header">
          <div className="career-collection__text-section">
            <h2 className="heading-2 heading-2--grows-on-desktop heading-2--white">
              {collection.title}
            </h2>
            {collection.backer_name && (
            <div className="career-collection__backer">
              <div className="career-collection__backer-text">
                <p className="body-copy-3 body-copy">
                  Backed by
                  {' '}
                  <br />
                  {collection.backer_name}
                </p>
              </div>
            </div>
            )}
          </div>
        </div>

        <div className="career-collection__text-section">
          <div className="career-collection__author-story">
            <p className="body-copy-1 body-copy-1--grows-on-desktop body-copy-1--gray-5">
              {collection.story}
            </p>
          </div>

        </div>

        <div className="career-collection__job-pathways-section">
          <h2 className="heading-2">
            {collection.job_pathway_headline}
          </h2>
          <div className="body-copy-subhead">
            {collection.job_pathway_subhead}
          </div>
          <div className="career-collection__portrait">
            <div className="collection__image career-collection__job-pathway-image">
              <img alt={collection.job_pathway_headline} src={collection.job_pathway_image_url} />
            </div>
          </div>
          <div className="career-collection__favorite-button-container">
            <Favorite
              favorite={myFavorite}
              object={collection}
              objectType={OBJECT_TYPES.careerCollection}
              loggedIn={!isEmpty(learner)}
              asIcon={false}
            />
          </div>
        </div>

        <div className="career-collection__collection-sections">
          {collection.collection_sections.map((collectionSection) => (
            <CareerCollectionSection
              key={collectionSection.id}
              headline={collectionSection.headline}
              subhead={collectionSection.subhead}
              completionRequirements={collectionSection.completion_requirements}
              learningObjects={collectionSection.learning_objects}
            />
          ))}
        </div>
      </article>
    </div>
  )
}

export default CareerCollection
