import { LearningObjectsActionTypes } from '../../../../actions'
import { LOADING_STATUSES, LEARNING_OBJECTS_FIRST_PAGE } from '../../../shared'

export const defaultState = {
  status: LOADING_STATUSES.notLoaded,
  page: LEARNING_OBJECTS_FIRST_PAGE,
  finalPageReached: false,
  items: [],
}

export default function learningObjects(state = defaultState, action) {
  switch (action.type) {
    case LearningObjectsActionTypes.LOAD:
      return { ...state, status: LOADING_STATUSES.loading }
    case LearningObjectsActionTypes.LOAD_SUCCESS:
      return {
        ...state,
        items: [
          ...state.items,
          ...action.learningObjects,
        ],
        finalPageReached: action.finalPageReached,
        status: LOADING_STATUSES.loaded,
      }
    case LearningObjectsActionTypes.INCREMENT_PAGE:
      return {
        ...state,
        page: state.page + 1,
        status: state.finalPageReached ? LOADING_STATUSES.loaded : LOADING_STATUSES.loading,
      }
    case LearningObjectsActionTypes.RESET:
      return defaultState
    default:
      return state
  }
}
