const { constants } = require('../constants')
const { api } = require('../helpers')

$(() => {
  const learningObjectCardClass = 'learning-object-card'
  const gridLearningObjectClass = 'grid__learning-object--with-divider'

  $('.js-in-progress-edit-trigger').on('click', (event) => {
    event.preventDefault()
    const $learningObjectCards = $(`.${learningObjectCardClass}`)
    const $markedAsFinished = $('.finish-button-form--state-finished')
    const $target = $(event.delegateTarget)
    if ($learningObjectCards.length > $markedAsFinished.length) {
      $target.toggleClass('my-stuff__edit-button--selected')
      $markedAsFinished
        .parents(`.${gridLearningObjectClass}`)
        .toggle('hide-when-editing')
      $learningObjectCards
        .parents(`.${gridLearningObjectClass}`)
        .toggleClass('grid__learning-object--editing')
    }
  })

  $('body').on('ajax:success', '.finish-button-form', () => {
    const $learningObjectCards = $(`.${learningObjectCardClass}`)
    const $markedAsFinished = $('.finish-button-form--state-finished')
    const $target = $('.js-in-progress-edit-trigger')
    if ($learningObjectCards.length === $markedAsFinished.length) {
      $target
        .removeClass('my-stuff__edit-button--selected')
        .addClass('my-stuff__edit-button--disabled')
    } else if ($learningObjectCards.length > $markedAsFinished.length) {
      $target.removeClass('my-stuff__edit-button--disabled')
    }
  })

  $('.js-unstart-learning-object').on('click', (event) => {
    const $target = $(event.delegateTarget)
    const $learningObjectCard = $target.siblings(`.${learningObjectCardClass}`)
    const url = constants.routes.learningObjectStatePath(
      $learningObjectCard.data('learningObjectId'),
    )

    /* eslint-disable no-underscore-dangle */
    /* eslint-disable no-console */
    const _method = 'delete'
    api
      .post(url, { _method })
      .done(() => $learningObjectCard
        .parents(`.${gridLearningObjectClass}`)
        .css('display', 'none'))
      .fail((err) => console.warn(err))
    /* eslint-enable no-underscore-dangle */
    /* eslint-enable no-console */
  })
})
