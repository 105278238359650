import React from 'react'

const EmailEditWarning = function () {
  return (
    <div className="my-stuff__profile-email-confirm-container js-my-stuff-profile-email-confirm-container">
      <div className="my-stuff__profile-email-confirm-bar-top" />
      <div className="my-stuff__profile-email-confirm">
        <div className="my-stuff__profile-email-confirm-text">
          <div className="my-stuff__profile-email-confirm-heading heading-1"> Wait!</div>
          <div className="my-stuff__profile-email-confirm-content">
            <p className="body-copy-2">Your email address helps us track your learning.</p>
          </div>
          <div className="my-stuff__profile-email-confirm-content">
            <p className="body-copy-2">
              Your email needs to match the one you&rsquo;
              ve used to sign in to our trusted partners`&apos; sites in order to give you credit for your hard work.
            </p>
          </div>
          <div className="my-stuff__profile-email-confirm-content">
            <p className="body-copy-2">
              Are you sure you want to change your Bendable email?
            </p>
          </div>
          <div className="my-stuff__profile-email-cancel-button js-my-stuff-profile-email-cancel-button">
            <button type="button" className="button button--solid-gold">
              <span className="button__text">No, Cancel</span>
            </button>
          </div>
          <div className="my-stuff__profile-email-continue-button js-my-stuff-profile-email-continue-button">
            <button type="button" className="button button--hollow-gold">
              <span className="button__text">Yes, Continue</span>
            </button>
          </div>
        </div>
        <div className="my-stuff__profile-email-confirm-image" />
      </div>
      <div className="my-stuff__profile-email-confirm-bar-bottom" />
    </div>
  )
}

export default EmailEditWarning
