import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'

import reducers from './reducers'
import sagas from './sagas'

export default function configureStore(initialState) {
  // create the saga middleware
  const sagaMiddleware = createSagaMiddleware()

  let createdStore

  if (process.env.RAILS_ENV === 'development') {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    createdStore = createStore(
      reducers,
      initialState,
      composeEnhancers(
        applyMiddleware(sagaMiddleware),
      ),
    )
  } else {
    createdStore = createStore(
      reducers,
      initialState,
      applyMiddleware(sagaMiddleware),
    )
  }

  // then run the sagas
  sagaMiddleware.run(sagas)

  return createdStore
}
