const LOAD = 'local/category/LOAD'
const LOAD_SUCCESS = 'local/category/LOAD_SUCCESS'
const LOAD_ERROR = 'local/category/LOAD_ERROR'

const load = (id) => ({ type: LOAD, id })
const loadSuccess = (category) => ({ type: LOAD_SUCCESS, category })
const loadError = (errors) => ({ type: LOAD_ERROR, errors })

export const CategoryActions = {
  load,
  loadSuccess,
  loadError,
}

export const CategoryActionTypes = {
  LOAD,
  LOAD_SUCCESS,
  LOAD_ERROR,
}
