/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import PropTypes from 'prop-types'

import ReactGA from 'react-ga'
import { TRACK_ANALYTICS, getUrlParams, getReferralPage } from '../shared'

import api from '../../services/api'
import routes from '../../services/routes'

const HandoffButton = function ({
  learningObject, learner, url, loState,
}) {
  const handleClick = () => {
    const referralPage = getReferralPage()
    const otherReferral = JSON.stringify(getUrlParams())
    const startPath = routes.learningObjects.start(learningObject.id)

    if (learner) {
      api
        .learningObjects
        .learningObjectState
        .update(learningObject.id, { other_referral: otherReferral, state: loState, referral_page: referralPage })
    } else {
      api
        .learningObjects
        .learningObjectState
        .create(learningObject.id, { other_referral: otherReferral, referral_page: referralPage })
    }

    if (TRACK_ANALYTICS) {
      ReactGA.pageview(startPath)
    }
  }

  return (
    <a
      href={url}
      className="button button--solid-gold branded-handoff__button"
      target="_blank"
      rel="noopener"
      onClick={handleClick}
    >
      <span className="button__text">
        Continue to site
      </span>
      <span className="button__icon button__icon--new-tab" />
    </a>
  )
}

HandoffButton.propTypes = {
  learningObject: PropTypes.object.isRequired,
  learner: PropTypes.object,
  url: PropTypes.string.isRequired,
  loState: PropTypes.string,
}

export default HandoffButton
