const LOAD = 'app/featuredLibraries/LOAD'
const LOAD_SUCCESS = 'app/featuredLibraries/LOAD_SUCCESS'
const LOAD_ERROR = 'app/featuredLibraries/LOAD_ERROR'

const load = () => ({ type: LOAD })
const loadSuccess = (featuredLibraries) => ({ type: LOAD_SUCCESS, featuredLibraries })
const loadError = (errors) => ({ type: LOAD_ERROR, errors })

export const FeaturedLibrariesActions = {
  load,
  loadSuccess,
  loadError,
}

export const FeaturedLibrariesActionTypes = {
  LOAD,
  LOAD_SUCCESS,
  LOAD_ERROR,
}
