import { ExploreSectionsActionTypes } from '../../../../actions'

export const defaultState = {
  items: [],
}

export default function exploreSections(state = defaultState, action) {
  switch (action.type) {
    case ExploreSectionsActionTypes.LOAD_SUCCESS:
      return { ...state, items: action.exploreSections }
    default:
      return state
  }
}
