import { all, spawn, call } from 'redux-saga/effects'

import loadBoards from '../../../sagas/loadBoards'
import loadBoard from '../../../sagas/loadBoard'
import loadLearningObject from '../../../sagas/loadLearningObject'
import loadTags from './loadTags'
import loadTopics from './loadTopics'
import loadProviders from './loadProviders'
import search from './search'
import loadCareerCollection from '../../../sagas/loadCareerCollection'
import loadCategories from './loadCategories'
import loadCategory from './loadCategory'
import loadLearningObjects from './loadLearningObjects'
import loadExploreSections from './loadExploreSections'
import loadHeroCarousel from './loadHeroCarousel'
import loadSpotlights from './loadSpotlights'
import loadFeaturedLibraries from './loadFeaturedLibraries'
import loadFeaturedTopics from './loadFeaturedTopics'
import loadFeaturedBoardGroups from './loadFeaturedBoardGroups'
import loadCollections from './loadCollections'
import loadHomepageSections from './loadHomepageSections'
import loadFaqSections from './loadFaqSections'
import loadFavorites from '../../../sagas/loadFavorites'
import loadFavoriteResources from '../../../sagas/loadFavoriteResources'

const sagas = [
  loadBoard,
  loadBoards,
  loadCareerCollection,
  loadCategories,
  loadCategory,
  loadCollections,
  loadExploreSections,
  loadFavorites,
  loadFavoriteResources,
  loadFeaturedLibraries,
  loadFeaturedTopics,
  loadFeaturedBoardGroups,
  loadHeroCarousel,
  loadHomepageSections,
  loadFaqSections,
  loadLearningObject,
  loadLearningObjects,
  loadProviders,
  loadSpotlights,
  loadTags,
  loadTopics,
  search,
]

export default function* rootSaga() {
  yield all(
    sagas.map((saga) => spawn(function* () {
      yield call(saga)
    })),
  )
}
