import React, { useEffect } from 'react'

import { useSelector, useDispatch } from 'react-redux'
import {
  CollectionActions,
  FavoritesActions,
  FavoriteResourcesActions,
  FeaturedLibrariesActions,
  FeaturedTopicsActions,
  FeaturedBoardGroupsActions,
  HeroCarouselActions,
  HomepageSectionsActions,
  SpotlightsActions,
} from '../../actions'
import { trackLearningObjectReferralPage, JRR_DISPLAYED } from '../shared'
import FeaturedLibraries from '../FeaturedLibraries'
import FeaturedTopics from '../FeaturedTopics'
import HeroCarousel from '../HeroCarousel'
import MyFavorites from '../MyFavorites'
import Spotlights from '../Spotlights'
import Collections from '../Collections'
import HomepageSectionsWrapper from './HomepageSectionsWrapper'
import FeaturedBoardGroups from '../FeaturedBoardGroups'
import JobReadinessRoom from '../JobReadinessRoom'

const HomePage = function () {
  const dispatch = useDispatch()
  const learner = useSelector((state) => state.app.learner)
  const {
    collections,
    featuredLibraries,
    featuredTopics,
    featuredBoardGroups,
    heroCarousel,
    homepageSections,
    spotlightCarousel,
  } = useSelector((state) => state.app.homepage)
  const favoriteResources = useSelector((state) => state.app.favoriteResources)

  useEffect(() => {
    trackLearningObjectReferralPage('home')
    dispatch(CollectionActions.load())
    dispatch(FavoritesActions.load())
    dispatch(FavoriteResourcesActions.load())
    dispatch(FeaturedLibrariesActions.load())
    dispatch(FeaturedTopicsActions.load())
    dispatch(FeaturedBoardGroupsActions.load())
    dispatch(HeroCarouselActions.load())
    dispatch(HomepageSectionsActions.load())
    dispatch(SpotlightsActions.load())
  }, [
    dispatch,
    FeaturedLibrariesActions.load,
    FeaturedTopicsActions.load,
    FeaturedBoardGroupsActions.load,
    CollectionActions.load,
    HeroCarouselActions.load,
    HomepageSectionsActions.load,
    SpotlightsActions.load,
  ])

  return (
    <div>
      <HeroCarousel slides={heroCarousel.slides} />
      <FeaturedLibraries featuredLibraries={featuredLibraries.featured_libraries} />
      <FeaturedTopics featuredTopics={featuredTopics} />
      <FeaturedBoardGroups featuredBoardGroups={featuredBoardGroups.featured_board_groups} />
      <Spotlights spotlights={spotlightCarousel.spotlights} learner={learner} />
      <MyFavorites favoriteResources={favoriteResources} learner={learner} />
      <Collections collections={collections} learner={learner} />
      <HomepageSectionsWrapper sections={homepageSections.sections} learner={learner} />
      {/* Currently only being used on Acceptance and Carlsbad */}
      {
        (process.env.RAILS_ENV === 'acceptance' || JRR_DISPLAYED)
          && <JobReadinessRoom />
      }
    </div>
  )
}

HomePage.propTypes = {}

export default HomePage
