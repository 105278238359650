import React from 'react'
import PropTypes from 'prop-types'
import { Swiper, SwiperSlide } from 'swiper/react' // eslint-disable-line import/no-unresolved
import { Pagination, A11y } from 'swiper' // eslint-disable-line import/no-unresolved
import LearningObjectCard from './LearningObjectCard'

const PairedLearningObjectCards = function ({
  pair,
  loggedIn,
}) {
  return (
    <div className={`paired-learning-objects pair-${pair.staff_pick.id} pair-${pair.more_click?.id}`}>
      <div className="paired-learning-objects__slider" id={`paired-learning-objects-${pair.id}`}>
        <Swiper
          modules={[Pagination, A11y]}
          pagination={{ clickable: true }}
          a11y={{
            firstSlideMessage: 'This is the first resource',
            lastSlideMessage: 'This is the last resource',
            paginationBulletMessage: 'View resource {{index}}',
          }}
        >
          <SwiperSlide key={pair.staff_pick.id}>
            <div className="paired-learning-objects__learning-object">
              <LearningObjectCard
                learningObject={pair.staff_pick}
                loggedIn={loggedIn}
                index={pair.style_index}
                bypassRedux
              />
            </div>
          </SwiperSlide>
          { pair.more_click
            && (
            <SwiperSlide key={pair.more_click?.id}>
              <div className="paired-learning-objects__learning-object">

                <LearningObjectCard
                  learningObject={pair.more_click}
                  loggedIn={loggedIn}
                  index={pair.style_index + 1}
                  bypassRedux
                />
              </div>
            </SwiperSlide>
            )}

        </Swiper>

      </div>
    </div>
  )
}

PairedLearningObjectCards.propTypes = {
  pair: PropTypes.object.isRequired,
  loggedIn: PropTypes.bool,
}

export default PairedLearningObjectCards
