import {
  FaqSectionsActionTypes,
} from '../../../../actions'
import { LOADING_STATUSES } from '../../../shared'

export const defaultState = {
  faqSections: {
    status: LOADING_STATUSES.notLoaded,
    sections: [],
  },
}

export default function faq(state = defaultState, action) {
  switch (action.type) {
    case FaqSectionsActionTypes.LOAD:
      return {
        ...state,
        faqSections: {
          ...state.faqSections,
          status: LOADING_STATUSES.loading,
        },
      }
    case FaqSectionsActionTypes.LOAD_SUCCESS:
      return {
        ...state,
        faqSections: {
          status: LOADING_STATUSES.loaded,
          sections: action.sections,
        },
      }
    case FaqSectionsActionTypes.LOAD_ERROR:
      return {
        ...state,
        faqSections: {
          ...state.faqSections,
          status: LOADING_STATUSES.notLoaded,
        },
      }
    default:
      return state
  }
}
