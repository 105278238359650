import { BoardsActionTypes } from '../../../../actions'

export const defaultState = {
  items: [],
}

export default function boards(state = defaultState, action) {
  switch (action.type) {
    case BoardsActionTypes.LOAD_SUCCESS:
      return { ...state, items: action.boards }
    default:
      return state
  }
}
