import { BoardActionTypes } from '../../../../actions'

export const defaultState = {
  item: {},
}

export default function board(state = defaultState, action) {
  switch (action.type) {
    case BoardActionTypes.LOAD_SUCCESS:
      return { ...state, item: action.board }
    case BoardActionTypes.CLEAR:
      return { ...state, item: {} }
    default:
      return state
  }
}
