import Cleave from 'cleave.js'

document.addEventListener('DOMContentLoaded', () => {
  document
    .querySelectorAll('input.js-library-card-number')
    .forEach((el) => {
      /* eslint-disable no-new */
      new Cleave(el, {
        numericOnly: true,
        blocks: [1, 4, 5, 4],
      })
      /* eslint-enable no-new */
    })
})
