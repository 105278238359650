import React from 'react'
import PropTypes from 'prop-types'
import HomepageSections from '../HomepageSections'

const HomepageSectionsWrapper = function ({ sections, learner }) {
  return (
    <div className="homepage-sections-wrapper">
      <HomepageSections homepageSections={sections} learner={learner} />
    </div>
  )
}

HomepageSectionsWrapper.propTypes = {
  sections: PropTypes.array,
  learner: PropTypes.object,
}

export default HomepageSectionsWrapper
