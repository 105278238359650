import React, { useEffect } from 'react'
import ReactMarkdown from 'react-markdown'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { isEmpty } from 'lodash'

import { LearningObjectActions } from '../../actions'
import { LinkRenderer, useQuery, NO_OF_LO_IMAGES } from '../shared'
import HandoffButton from './HandoffButton'
import Indicators from './Indicators'
import Logos from './Logos'
import { learningObjectTypes } from './constants'
import CommunityLearningPartnerHandoffPage from './CommunityLearningPartnerHandoffPage'

const HandoffPage = function () {
  const dispatch = useDispatch()
  const { id } = useParams()
  const query = useQuery()
  const learningObject = useSelector((state) => state.local.learningObject.item)
  const bgImage = query.get('bg_img') || (learningObject?.title?.length || 0) % NO_OF_LO_IMAGES
  const learningObjectNotLoaded = () => isEmpty(learningObject) || String(learningObject.id) !== id

  const SUBSCRIPTION_TYPES = {
    NONE: 'None',
    LIBRARY: 'Library',
    BENDABLE: 'Bendable',
  }

  useEffect(() => {
    $('html,body').scrollTop(0)
  }, [])

  useEffect(() => {
    if (learningObjectNotLoaded()) {
      dispatch(LearningObjectActions.load(id))
    }
  }, [dispatch, LearningObjectActions.load, learningObject])

  if (learningObjectNotLoaded()) return null

  if (learningObject.learning_object_type === learningObjectTypes.community_partner) {
    return <CommunityLearningPartnerHandoffPage learningObject={learningObject} />
  }

  return (
    <div className="branded-handoff">
      <div className={`branded-handoff__image branded-handoff__image--bg-image-${bgImage}`} />
      <h1 className="branded-handoff__header heading-1 heading-1--grows-on-desktop">{learningObject.title}</h1>
      <div className="branded-handoff__actions">
        <div className="branded-handoff__topic">{learningObject.topic}</div>
        <Logos learningObject={learningObject} imageClassName="branded-handoff__logo" />
      </div>
      <div className="branded-handoff__text-block">
        <div className="body-copy-2">
          <ReactMarkdown children={learningObject.bendable_description} />
        </div>
      </div>
      {
        (learningObject.provider
        && learningObject.provider.subscription_type === SUBSCRIPTION_TYPES.LIBRARY) && (
          <div className="branded-handoff__text-block">
            <h4 className="branded-handoff__subhead">Sign up for a free account</h4>
            <div className="body-copy-2">
              <div className="bendable-markdown">
                <p>
                  It&apos;s easy to sign up for a free
                  {' '}
                  {learningObject.provider.name}
                  {' '}
                  learning account.
                  Just click the &apos;Sign up&apos; button and use your library card to create an account in
                  the new tab that opens.
                </p>
              </div>
            </div>
            <div className="branded-handoff__library-sign-up-button-container">
              <a
                href={learningObject.provider.sign_up_url}
                className="button button--solid-gold branded-handoff__library-sign-up-button"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="button__text">
                  Sign up
                </span>
                <span className="button__icon button__icon--new-tab" />
              </a>
            </div>
          </div>
        )
      }
      <div className="branded-handoff__more">
        <h4 className="branded-handoff__subhead">More details</h4>
        <Indicators learningObject={learningObject} />
      </div>
      <div className="branded-handoff__button-container">
        <HandoffButton
          learningObject={learningObject}
          learner={learningObject.learner}
          loState={learningObject.state}
          url={learningObject.url}
        />
      </div>
      <div className="branded-handoff__text-block">
        <h4 className="branded-handoff__subhead">What to expect</h4>
        {
          learningObject.handoff_content_short_description && (
            <ReactMarkdown
              components={{ a: LinkRenderer }}
              className="bendable-markdown"
              children={learningObject.handoff_content_short_description}
            />
          )
        }
      </div>
    </div>
  )
}

HandoffPage.propTypes = {}

export default HandoffPage
