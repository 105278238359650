const LOAD = 'app/boards/LOAD'
const LOAD_SUCCESS = 'app/boards/LOAD_SUCCESS'
const LOAD_ERROR = 'app/boards/LOAD_ERROR'

const load = () => ({ type: LOAD })
const loadSuccess = (boards) => ({ type: LOAD_SUCCESS, boards })
const loadError = (errors) => ({ type: LOAD_ERROR, errors })

export const BoardsActions = {
  load,
  loadSuccess,
  loadError,
}

export const BoardsActionTypes = {
  LOAD,
  LOAD_SUCCESS,
  LOAD_ERROR,
}
