const LOAD = 'app/spotlights/LOAD'
const LOAD_SUCCESS = 'app/spotlights/LOAD_SUCCESS'
const LOAD_ERROR = 'app/spotlights/LOAD_ERROR'

const load = () => ({ type: LOAD })
const loadSuccess = (spotlights) => ({ type: LOAD_SUCCESS, spotlights })
const loadError = (errors) => ({ type: LOAD_ERROR, errors })

export const SpotlightsActions = {
  load,
  loadSuccess,
  loadError,
}

export const SpotlightsActionTypes = {
  LOAD,
  LOAD_SUCCESS,
  LOAD_ERROR,
}
