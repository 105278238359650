import React from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'

const Story = function ({ homepageSection }) {
  return (
    <section className="content-section" id={homepageSection.anchor_tag}>
      <div className="section-header--story">
        <div className="section-header__head">
          <h2 className="heading-2">
            <ReactMarkdown children={homepageSection.header} />
          </h2>
        </div>
      </div>
      <div className="content-section__container--story">
        <img
          className="content-section__image"
          src={homepageSection.image}
          alt={homepageSection.image_alt_text}
        />
        <div className="content-section__story">
          <ReactMarkdown children={homepageSection.story} />
        </div>
      </div>
    </section>
  )
}

Story.propTypes = {
  homepageSection: PropTypes.object.isRequired,
}

export default Story
