import React from 'react'
import ReactMarkdown from 'react-markdown'
import { HeadingRenderer, JRR_URL } from '../shared'

import JRRIcon1 from '../../../assets/images/job-readiness-room-icon-1.png'
import JRRIcon2 from '../../../assets/images/job-readiness-room-icon-2.png'

function Heading(props) {
  return <HeadingRenderer className="job-readiness-room__headline" properties={props} />
}

const JobReadinessRoom = function () {
  const headline = 'The Job-Readiness Room'
  const headlinePt2 = 'powered by Lightcast'
  const subhead = 'Looking for an entry-level job? Get a leg up with local employers in '
        + 'any of these six fields: customer service, healthcare, hospitality and food service, '
        + 'logistics, manufacturing or office administration.'
  const goToJRR = function () {
    window.open(JRR_URL, '_blank')
  }

  return (
    <div className="job-readiness-room">
      <div className="section-header">
        <hr className="job-readiness-room__hr" />
        <ReactMarkdown
          children={headline}
          components={{ p: Heading }}
        />
        <ReactMarkdown
          children={headlinePt2}
          components={{ p: Heading }}
        />
        <ReactMarkdown
          className="job-readiness-room__subhead"
          children={subhead}
        />
        <div className="job-readiness-room__content">
          <div className="job-readiness-room__learn-more">
            <button
              className="button button--hollow-carnation"
              type="button"
              alt-text="Go to JRR Page"
              onClick={() => goToJRR()}
            >
              <span className="button__text">Learn more and get started</span>
            </button>
          </div>
          <div className="job-readiness-room__icon-container">
            <img className="job-readiness-room__icon" src={JRRIcon1} alt="" />
            <img className="job-readiness-room__icon" src={JRRIcon2} alt="" />
          </div>
        </div>
      </div>
    </div>
  )
}

JobReadinessRoom.propTypes = {}

export default JobReadinessRoom
