import React from 'react'
import { useSelector } from 'react-redux'

import SearchEmpty from './SearchEmpty'
import { LOADING_STATUSES } from '../shared'

const ResultsHeader = function () {
  const resultLength = useSelector((state) => state.local.search.resultLength)
  const status = useSelector((state) => state.local.search.status)

  return (
    <>
      <div className="search-results__header-container">
        <h2 className="heading-2">Search Results</h2>
      </div>
      {
      resultLength > 0
        && status === LOADING_STATUSES.loaded && (
        <div className="search-results__message">
          <p className="body-copy-2">
            We found
            {' '}
            {resultLength}
            {' '}
            {resultLength > 1 ? 'items' : 'item'}
            {' '}
            that match
            { resultLength === 1 ? 'es' : ''}
            {' '}
            your search.
          </p>
        </div>
      )
    }
      {
      resultLength === 0
        && status === LOADING_STATUSES.loaded
        && (<SearchEmpty />)
    }
    </>
  )
}

export default ResultsHeader
