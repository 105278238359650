import { CategoriesActionTypes } from '../../../../actions'
import { LOADING_STATUSES } from '../../../shared'

export const defaultState = {
  status: LOADING_STATUSES.notLoaded,
  items: [],
}

export default function categories(state = defaultState, action) {
  switch (action.type) {
    case CategoriesActionTypes.LOAD:
      return { ...state, status: LOADING_STATUSES.loading }
    case CategoriesActionTypes.LOAD_SUCCESS:
      return {
        ...state,
        items: action.categories,
        status: LOADING_STATUSES.loaded,
      }
    default:
      return state
  }
}
