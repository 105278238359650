import React, { useEffect } from 'react'
import ReactMarkdown from 'react-markdown'
import classNames from 'classnames'
import { useSelector, useDispatch } from 'react-redux'
import { LinkRenderer, SubheadAndCTARenderer } from '../shared'
import { FaqSectionsActions } from '../../actions'

const FaqPage = function () {
  const dispatch = useDispatch()
  const { sections } = useSelector((state) => state.app.faq.faqSections)

  useEffect(() => {
    dispatch(FaqSectionsActions.load())
  }, [
    dispatch,
    FaqSectionsActions.load,
  ])

  return (
    <main className="faq-page">
      {sections.map(({
        headline, body, id, variant,
      }) => (
        <section
          className={classNames(
            'faq-page__section',
            variant === 'two_column' && 'faq-page__section--two-column',
          )}
          key={id}
        >
          <h1 className="faq-page__headline">
            {headline}
          </h1>
          <ReactMarkdown
            className="faq-page__body"
            components={
              {
                a: LinkRenderer,
                h1: SubheadAndCTARenderer,
              }
            }
          >
            {body}
          </ReactMarkdown>
        </section>
      ))}
    </main>
  )
}

FaqPage.propTypes = {}

export default FaqPage
