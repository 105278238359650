import React from 'react'
import ReactMarkdown from 'react-markdown'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import classnames from 'classnames'
import { isEmpty } from 'lodash'

import CommunityLearningPartnerCard from './CommunityLearningPartnerCard'

import {
  Favorite,
  selectFavorite,
  NO_OF_LO_IMAGES,
  OBJECT_TYPES,
  USER_STATUSES,
  FLASH_NOTIFICATIONS,
} from '../shared'
import LearningFormatIcon from './LearningFormatIcon'
import Logos from './Logos'

import { learningObjectTypes } from './constants'

const LearningObjectCard = function ({
  learningObject,
  index,
  loggedIn, // this should be replaced with learner checks when things are all react
  bypassRedux,
}) {
  const myFavorite = bypassRedux
    ? learningObject.favorite
    : useSelector(selectFavorite(learningObject, OBJECT_TYPES.learningObject))
  const linkId = `learning_object_${learningObject.id}`
  const imageNumber = index % NO_OF_LO_IMAGES
  const learner = bypassRedux ? null : useSelector((state) => state.app.learner)

  const flashNotification = () => {
    if (learningObject.flash_notification) { return learningObject.flash_notification }

    if (!learningObject.prepaid_seats) { return false }

    if (isEmpty(learner)) {
      return FLASH_NOTIFICATIONS.loginForPaidResource
    }

    if (learner?.status === USER_STATUSES.expired) {
      return FLASH_NOTIFICATIONS.libraryCardRequiredForPaidResource
    }

    return null
  }

  if (learningObject.learning_object_type === learningObjectTypes.community_partner) {
    return (
      <CommunityLearningPartnerCard
        learningObject={learningObject}
        index={index}
        linkId={linkId}
        bypassRedux={bypassRedux}
      />
    )
  }

  return (
    <div
      className="learning-object-card"
      data-learning-object-id={learningObject.id}
      id={linkId}
    >
      <a
        to={`/learning_objects/${learningObject.id}`}
        className={classnames(
          'learning-object-card__link',
          { 'js-trigger-flash-notification': flashNotification() },
        )}
        title={`${learningObject.title}`}
        type="LearningObject"
        data-targeted-notification={linkId}
        data-anchor-tag={linkId}
        data-flash-notification={flashNotification()}
        href={`/learning_objects/${learningObject.id}?bg_img=${imageNumber}`}
      >
        <div className="learning-object-card__assistive-text">{learningObject.title}</div>
        <div className="learning-object-card__container">
          <div className="learning-object-card__content">
            <Favorite
              favorite={myFavorite}
              object={learningObject}
              objectType={OBJECT_TYPES.learningObject}
              loggedIn={loggedIn}
            />
            <div className="learning-object-card__image-container">
              <div
                className={
                  `learning-object-card__image learning-object-card__image--bg-image-${imageNumber}`
                }
              />
            </div>
            <div className="learning-object-card__header">
              <h5 className="learning-object-card__topic">{learningObject.topic}</h5>
              {
                learningObject.fee_required && (
                  <div className="learning-object-card__indicator">
                    <div className="learning-object-card__icon learning-object-card__icon--fee" />
                  </div>
                )
              }
            </div>
            <div className="learning-object-card__title">
              <h4 className="heading-4">
                <ReactMarkdown children={learningObject.title} />
              </h4>
            </div>
            <div className="learning-object-card__description">
              <ReactMarkdown children={learningObject.bendable_description} />
            </div>
            <div className="learning-object-card__more">More details</div>
          </div>
        </div>
      </a>
      <a
        to={`/learning_objects/${learningObject.id}`}
        className={classnames(
          'learning-object-card__link--footer',
          { 'js-trigger-flash-notification': flashNotification() },
        )}
        title={`${learningObject.title}`}
        type="LearningObject"
        data-targeted-notification={linkId}
        data-anchor-tag={linkId}
        data-flash-notification={flashNotification()}
        href={`/learning_objects/${learningObject.id}?bg_img=${imageNumber}`}
      >
        <div className="learning-object-card__assistive-text">{learningObject.title}</div>
      </a>
      <div className="learning-object-card__footer-container">
        <div className="learning-object-card__footer-row">
          <LearningFormatIcon
            learningFormat={learningObject.learning_format}
            location={learningObject.location}
            className="learning-object-card__icon"
          />
        </div>
        <div className="learning-object-card__footer-row">
          <Logos
            learningObject={learningObject}
            imageClassName="provider-logo__image"
          />
        </div>
      </div>
    </div>
  )
}

LearningObjectCard.propTypes = {
  learningObject: PropTypes.object.isRequired,
  index: PropTypes.number,
  loggedIn: PropTypes.bool,
  bypassRedux: PropTypes.bool,
}

export default LearningObjectCard
