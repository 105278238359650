import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'

import GridChooser from './GridChooser'
import { Button } from '../shared'

const BASE_NUM_TO_DISPLAY = 3

const GridExpanderComponent = function ({
  items,
  numToDisplay = BASE_NUM_TO_DISPLAY,
  seeMoreText,
  type,
  learner,
}) {
  const showExpander = items.length > numToDisplay
  const [collapsed, setCollapsed] = useState(true)
  const buttonText = `See ${collapsed ? 'more' : 'fewer'} ${seeMoreText}`
  const gridRef = useRef()

  if (showExpander) {
    return (
      <div className="content__grid">
        <div ref={gridRef} className="grid-expander">
          <GridChooser
            type={type}
            items={collapsed ? items.slice(0, numToDisplay) : items}
            learner={learner}
            bypassRedux
          />
          <div
            className={`grid-expander__button grid-expander__button--${type}`}
          >
            <Button
              icon={collapsed ? 'arrow-down-gray-2' : 'arrow-up-gray-2'}
              variant="hollow-carnation"
              onClick={() => {
                if (collapsed) {
                  setCollapsed(false)
                } else {
                  setCollapsed(true)
                  gridRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
                }
              }}
              content={buttonText}
            />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="content__grid">
      <GridChooser
        type={type}
        items={items}
        learner={learner}
        bypassRedux
      />
    </div>
  )
}

GridExpanderComponent.propTypes = {
  items: PropTypes.array.isRequired,
  numToDisplay: PropTypes.number,
  seeMoreText: PropTypes.string,
  type: PropTypes.string,
  learner: PropTypes.object,
}

export default GridExpanderComponent
