const LOAD = 'local/learningObjects/LOAD'
const LOAD_SUCCESS = 'local/learningObjects/LOAD_SUCCESS'
const LOAD_ERROR = 'local/learningObjects/LOAD_ERROR'
const INCREMENT_PAGE = 'local/learningObjects/INCREMENT_PAGE'
const RESET = 'local/learningObjects/RESET'

const load = (parentId, page) => ({ type: LOAD, parentId, page })
const loadSuccess = (learningObjects, finalPageReached) => ({
  type: LOAD_SUCCESS,
  learningObjects,
  finalPageReached,
})
const loadError = (errors) => ({ type: LOAD_ERROR, errors })
const incrementPage = () => ({ type: INCREMENT_PAGE })
const reset = () => ({ type: RESET })

export const LearningObjectsActions = {
  load,
  loadSuccess,
  loadError,
  incrementPage,
  reset,
}

export const LearningObjectsActionTypes = {
  LOAD,
  LOAD_SUCCESS,
  LOAD_ERROR,
  INCREMENT_PAGE,
  RESET,
}
