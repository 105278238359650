import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'

import { PairedLearningObjectCards } from '../LearningObjects'

const GridPairedLearningObjectCards = function ({ pairs, learner }) {
  return (
    <div className="grid grid--learning-objects">
      {
        pairs.map((pair) => (
          <div key={pair.id} className="grid__learning-object--with-divider">
            <div className="grid__divider">
              <div className="divider" />
            </div>
            <div className="grid__learning-object">
              <PairedLearningObjectCards
                pair={pair}
                loggedIn={!isEmpty(learner)}
              />
            </div>
          </div>
        ))
      }
    </div>
  )
}

GridPairedLearningObjectCards.propTypes = {
  pairs: PropTypes.array.isRequired,
  learner: PropTypes.object,
}

export default GridPairedLearningObjectCards
