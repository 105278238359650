import React from 'react'
import PropTypes from 'prop-types'
import { BrowserRouter as Router } from 'react-router-dom'

import Nav from './Nav'

export { default as Back } from './Back'

const Navbar = function ({
  displayNotification,
  signedIn,
  showEngage,
  outsideApp,
}) {
  if (!outsideApp) {
    return (
      <Nav
        displayNotification={displayNotification}
      />
    )
  }

  return (
    <Router>
      <Nav
        displayNotification={displayNotification}
        signedIn={signedIn}
        showEngage={showEngage}
        outsideApp
      />
    </Router>
  )
}

Navbar.propTypes = {
  displayNotification: PropTypes.bool,
  signedIn: PropTypes.bool,
  outsideApp: PropTypes.bool,
  showEngage: PropTypes.bool,
}

export default Navbar
