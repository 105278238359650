import { FavoriteResourcesActionTypes } from '../../../../actions'
import { LOADING_STATUSES } from '../../../shared'

export const defaultState = {
  status: LOADING_STATUSES.notLoaded,
  items: [],
}

export default function favoriteResources(state = defaultState, action) {
  switch (action.type) {
    case FavoriteResourcesActionTypes.LOAD:
      return { ...state, status: LOADING_STATUSES.loading }
    case FavoriteResourcesActionTypes.LOAD_SUCCESS:
      return {
        ...state,
        items: action.favoriteResources,
        status: LOADING_STATUSES.loaded,
      }
    default:
      return state
  }
}
