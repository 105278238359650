import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { RailsStyleInput, RailsStyleCheckbox } from '../shared'

const SendSms = function ({ mobilePhoneNumber, sendSms, errors }) {
  const [mobileNumber, setMobileNumber] = useState(mobilePhoneNumber)
  const [sendSmsLocal, setSendSmsLocal] = useState(sendSms)

  return (
    <>
      <RailsStyleCheckbox
        key="sms"
        label="I want Bendable to SMS message/text me"
        className="form__input-container--checkbox"
        fieldFor="user"
        fieldName="send sms"
        value={sendSmsLocal}
        onChange={() => {
          const newSetSms = !sendSmsLocal
          setSendSmsLocal(!sendSmsLocal)
          if (newSetSms === false) {
            setMobileNumber('')
          }
        }}
      />
      {sendSmsLocal && (
      <RailsStyleInput
        key="phone"
        pattern="[0-9]*"
        fieldFor="user"
        fieldName="mobile phone number"
        type="number"
        value={mobileNumber || ''}
        onChange={(e) => {
          setMobileNumber(e.target.value)
        }}
        errors={errors}
      />
      )}
    </>
  )
}

SendSms.propTypes = {
  sendSms: PropTypes.bool,
  mobilePhoneNumber: PropTypes.string,
  errors: PropTypes.object,
}

export default SendSms
