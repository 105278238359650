import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { camelCase } from 'lodash'

/**
 * Extracts params such as "search_term" from the location's query string so that
 * the search input box can be populated when loading a /explore/search URL and
 * returns the params in camel case
 *
 * @param {*} reqQueries
 * @returns
 */
export default function useQuery(reqQueries) {
  // const shouldLog = false;
  const { search } = useLocation()
  // if (shouldLog) console.log('useQuery.js search: ', search);

  return useMemo(
    () => {
      const query = new URLSearchParams(search, [search])
      const params = {}

      reqQueries.forEach(
        (req) => {
          params[camelCase(req)] = query.get(req)
        },
      )
      // if (shouldLog) console.log('useQuery.js params: ', params);
      return params
    },
  )
}
