$(() => {
  $('.custom-checkbox').keydown((e) => {
    // Check for enter or space key press while the checkbox is focused on
    if (e.which === 13 || e.which === 32) {
      const checkbox = $('#user_email_notification')
      const checkboxVal = checkbox.prop('checked')
      // By default the Rails page will try to submit the form whenever
      // the enter button is pressed, regardless of where focus is
      e.preventDefault()
      checkbox.prop('checked', !checkboxVal)
      checkbox.prop('aria-checked', !checkboxVal)
    }
  })
})

// Note: Currently used on the Sign Up page for the "Email me" checkbox
