import React from 'react'
import ReactMarkdown from 'react-markdown'
import PropTypes from 'prop-types'
import { HeadingRenderer } from '../shared'

function Heading(props) {
  return <HeadingRenderer className="hero-carousel__heading heading-banner heading-banner--jumbo" properties={props} />
}

const HeroCarouselSlide = function ({ header, subheader, image }) {
  return (
    <div className="hero-carousel__slide">
      <img className="hero-carousel__image" src={image.url} alt={image.alt} />
      <div className="hero-carousel__text-container">
        <div className="hero-carousel__text">
          <ReactMarkdown
            children={header}
            components={{ p: Heading }}
          />

          <ReactMarkdown
            className="hero-carousel__subheader"
            children={subheader}
          />
        </div>
      </div>
    </div>
  )
}

export const slideProperties = {
  header: PropTypes.string,
  subheader: PropTypes.string,
  image: PropTypes.shape({ url: PropTypes.string, alt: PropTypes.string }),
}

HeroCarouselSlide.propTypes = slideProperties

export default HeroCarouselSlide
