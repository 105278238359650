import { TagsActionTypes } from '../../../../actions'
import { LOADING_STATUSES } from '../../../shared'

export const defaultState = {
  items: [],
  status: LOADING_STATUSES.notLoaded,
}

export default function tags(state = defaultState, action) {
  switch (action.type) {
    case TagsActionTypes.LOAD:
      return { ...state, status: LOADING_STATUSES.loading }
    case TagsActionTypes.LOAD_SUCCESS:
      return { ...state, items: action.tags, status: LOADING_STATUSES.loaded }
    default:
      return state
  }
}
