import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import ReactMarkdown from 'react-markdown'
import { useSelector } from 'react-redux'
import classnames from 'classnames'

import { LearningObjectCardSmall } from '../LearningObjects'
import { GridLearningObjects } from '../GridComponents'

const OFFSET_FROM_SHOW_BUTTON = 200

const FeaturedCollection = function ({ collection }) {
  if (isEmpty(collection)) {
    return null
  }

  const learner = useSelector((state) => state.app.learner)

  const [showLearningObjects, setShowLearningObjects] = useState(false)

  const [coordinatesOfShowButton, setCoordinatesOfShowButton] = useState(0)
  const showLearningObjectsButtonRef = useRef()

  const [showLearningObjectsOnTablet, setShowLearningObjectsOnTablet] = useState(false)
  const [coordinatesOfTabletShowButton, setCoordinatesOfTabletShowButton] = useState(0)
  const showOnTabletButtonRef = useRef()

  const featuredCollectionUrl = `${collection.type === 'CommunityCollection'
    ? 'community_collection'
    : 'career_collection'}s/${collection.id}`

  // Used on Mobile
  const onShowClick = () => {
    if (!showLearningObjects) {
      setCoordinatesOfShowButton(
        showLearningObjectsButtonRef.current.offsetTop - OFFSET_FROM_SHOW_BUTTON,
      )
    } else {
      window.scrollTo(0, (coordinatesOfShowButton))
    }
    setShowLearningObjects(!showLearningObjects)
  }

  // Used only for smaller screens (media-breakpoint "small")
  const expandOnTablet = () => {
    if (!showLearningObjectsOnTablet) {
      setCoordinatesOfTabletShowButton(
        showOnTabletButtonRef.current.offsetTop - OFFSET_FROM_SHOW_BUTTON,
      )
    } else {
      window.scrollTo(0, (coordinatesOfTabletShowButton))
    }
    setShowLearningObjectsOnTablet(!showLearningObjectsOnTablet)
  }

  const handleAccessibleExpandOnTablet = (e) => {
    // Accessibility function that toggles the "Show All" button
    // when 'space' or 'return' key is pressed
    if (e.which === 13 || e.which === 32) {
      e.preventDefault()
      expandOnTablet()
    }
  }

  return (
    <>
      <section key="desktop" className="featured-collections">
        <div className="featured-collections__header">
          <a className="featured-collections__link" href={featuredCollectionUrl}>
            <h3 className="featured-collections__heading">
              Featured Collection
            </h3>
          </a>
        </div>

        <div className="featured-collections__content">
          <a className="featured-collections__link" href={featuredCollectionUrl}>
            <div className="featured-collections__intro">
              <div className="featured-collections__portrait collection-card__portrait">
                <div
                  className="overlaid-image featured-collections__image"
                  style={{ backgroundImage: `url('${collection.image_url}')` }}
                />
              </div>
              <div className="featured-collections__description">
                <h2 className="featured-collections__title">
                  <ReactMarkdown children={collection.title} />
                </h2>
                {
                  collection.author && (
                    <div className="featured-collections__author">
                      Collection by
                      {' '}
                      { collection.author }
                    </div>
                  )
                }
                {
                  collection.backer_name && (
                    <div className="featured-collections__backer">
                      <img
                        alt={collection.backer_name}
                        className="featured-collections__backer-logo"
                        src={collection.backer_logo_url}
                      />
                      <span className="featured-collections__backer-name">
                        { collection.backer_name }
                      </span>
                    </div>
                  )
                }
              </div>
            </div>
          </a>
          <div className={
            classnames(
              'featured-collections__learning-objects',
              { 'featured-collections__tablet-expanded': showLearningObjectsOnTablet },
            )
}
          >
            {
              collection.learning_objects.map((learningObject, i) => (
                <LearningObjectCardSmall
                  key={learningObject.id}
                  learningObject={learningObject}
                  collectionType={collection.type}
                  index={i}
                />
              ))
            }
          </div>
          <div className="featured__tablet-show-more">
            <button
              ref={showOnTabletButtonRef}
              className="button button--hollow-carnation"
              type="button"
              onClick={expandOnTablet}
              onKeyPress={handleAccessibleExpandOnTablet}
            >
              <span className="button__text">See more</span>
              <span className={
                classnames(
                  {
                    'button__icon button__icon--arrow-down-gray-2': showLearningObjectsOnTablet === false,
                    'button__icon button__icon--arrow-up-gray-2': showLearningObjectsOnTablet === true,
                  },
                )
              }
              />
            </button>
          </div>
        </div>
      </section>

      {/* eslint-disable jsx-a11y/click-events-have-key-events */
      /* eslint-disable jsx-a11y/no-static-element-interactions */}
      <section key="mobile" className="featured-collections--mobile">
        <div className="featured-collections__header--mobile">
          <h3 className="featured-collections__heading--mobile">
            Featured Collection
          </h3>
        </div>
        <div className="featured-collections__content--mobile">
          <div className="featured-collections__intro--mobile">
            <div className="featured-collections__portrait--mobile collection-card__portrait">
              <div
                className="overlaid-image featured-collections__image--mobile"
                style={{ backgroundImage: `url('${collection.image_url}')` }}
              />
            </div>
            <div className="featured-collections__description--mobile">
              <h2 className="featured-collections__title">
                <ReactMarkdown children={collection.title} />
              </h2>
              <div>
                Collection by
                {' '}
                { collection.author }
              </div>
              {
                collection.backer_name && (
                  <div className="featured-collections__backer">
                    <img
                      alt={collection.backer_name}
                      className="featured-collections__backer-logo"
                      src={collection.backer_logo_url}
                    />
                    <span className="featured-collections__backer-name">
                      { collection.backer_name }
                    </span>
                  </div>
                )
              }
            </div>
          </div>
          {
            showLearningObjects && (
              <div className="featured-collections__learning-objects--mobile">
                <GridLearningObjects
                  learningObjects={collection.learning_objects}
                  learner={learner}
                  componentName={LearningObjectCardSmall}
                />
              </div>
            )
          }
          <div
            ref={showLearningObjectsButtonRef}
            className={
              classnames(
                'featured-collections__expand-button',
                { 'featured-collections__expand-button--rotated': showLearningObjects },
              )
}
            onClick={onShowClick}
          />
        </div>
      </section>
    </>
  )
}

FeaturedCollection.propTypes = {
  collection: PropTypes.shape({
    image_url: PropTypes.string,
    title: PropTypes.string,
    author: PropTypes.string,
    backer_name: PropTypes.string,
    backer_logo_url: PropTypes.string,
    learning_objects: PropTypes.array,
    type: PropTypes.string,
    id: PropTypes.number,
  }),
}

export default FeaturedCollection
