const { constants } = require('../constants')
const { api } = require('../helpers')

function getUserInputValues() {
  const userFields = [
    'first_name',
    'last_name',
    'email',
    'password',
    'password_confirmation',
    'zip_code',
    'library_card_number',
    'library_card_pin',
  ]

  return userFields.reduce((acc, key) => {
    acc[key] = $(`#user_${key}`)[0]?.value
    return acc
  }, {})
}

/* eslint-disable no-restricted-syntax */
function markInvalidEntries(errors) {
  for (const [field, errorMessages] of Object.entries(errors)) {
    const $field = $(`#user_${field}`)
    $field.toggleClass('form__input-field--error')
    $field.after(`<div class="form__field-error">${errorMessages[0]}</div>`)
  }
}
/* eslint-enable no-restricted-syntax */

function clearAllErrors() {
  $('.form__field-error').remove()
  $('.form__input-field').removeClass('form__input-field--error')
}

$(() => {
  const form = $('.js-registration-form')

  if (form.length) {
    const toggler = () => $('.form__wizard-step').toggleClass('form__wizard-step--active')
    const submitForm = () => form.submit()
    const validateUserInputValues = (e, currentStep, successCb) => {
      e.preventDefault()
      $(e.delegateTarget).toggleClass('sign-up__button--disabled')
      clearAllErrors()
      const url = constants.routes.apiUserValidatePath()
      const data = { user: getUserInputValues(), current_step: currentStep }
      api
        .post(url, data)
        .done((resp) => {
          if (resp.valid) {
            successCb()
          } else {
            markInvalidEntries(resp.errors)
          }
        })
        .always(() => $(e.delegateTarget).toggleClass('sign-up__button--disabled'))
    }

    $('.js-registration-back-button').on('click', (e) => {
      e.preventDefault()
      toggler()
    })
    $('.js-registration-next-button').on('click', (e) => validateUserInputValues(e, 'one', toggler))
    $('.form__submit-button').on('click', (e) => validateUserInputValues(e, 'two', submitForm))
  }
})
