import createSubscription from './createSubscription'

const CHANNEL_NAME = 'MyStuffActivityMonitorChannel'

$(() => {
  createSubscription(CHANNEL_NAME, (data) => {
    $('.js-activity-monitor').toggleClass(
      'navbar__activity--active',
      data.display_notification,
    )
  })
})
