const LOAD = 'local/careerCollection/LOAD'
const LOAD_SUCCESS = 'local/careerCollection/LOAD_SUCCESS'
const LOAD_ERROR = 'local/careerCollection/LOAD_ERROR'
const CLEAR = 'local/careerCollection/CLEAR'

const load = (id) => ({ type: LOAD, id })
const loadSuccess = (careerCollection) => ({ type: LOAD_SUCCESS, careerCollection })
const loadError = (errors) => ({ type: LOAD_ERROR, errors })
const clear = () => ({ type: CLEAR })

export const CareerCollectionActions = {
  load,
  loadSuccess,
  loadError,
  clear,
}

export const CareerCollectionActionTypes = {
  LOAD,
  LOAD_SUCCESS,
  LOAD_ERROR,
}
