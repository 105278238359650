import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import classNames from 'classnames'

import { LearningObjectCard } from '../LearningObjects'
import { CommunityCollectionCard, CareerCollectionCard } from '../Collection'
import { isLearningObject, isCommunityCollection, isCareerCollection } from '../shared'

const GridCombined = function ({
  objects, learner, bypassRedux, itemClassName,
}) {
  return (
    <div className="grid grid--learning-objects">
      {
        objects.map((obj, i) => (
          <div key={obj.id} className={classNames('grid__learning-object--with-divider', itemClassName)}>
            <div className="grid__divider">
              <div className="divider" />
            </div>
            <div className="grid__learning-object">
              {
                isLearningObject(obj) && (
                  <LearningObjectCard
                    learningObject={obj}
                    index={i}
                    loggedIn={!isEmpty(learner)}
                    bypassRedux={bypassRedux}
                  />
                )
              }
              {
                isCommunityCollection(obj) && (
                  <CommunityCollectionCard
                    key={obj.id}
                    collection={obj}
                    index={i}
                    loggedIn={!isEmpty(learner)}
                    bypassRedux={bypassRedux}
                    className="collection-card--search"
                  />
                )
              }
              {
                isCareerCollection(obj) && (
                  <CareerCollectionCard
                    key={obj.id}
                    collection={obj}
                    loggedIn={!isEmpty(learner)}
                    bypassRedux={bypassRedux}
                    className="collection-card--search"
                  />
                )
              }
              <div className="learning-object-card__x-out-button js-unstart-learning-object" />
            </div>
          </div>
        ))
      }
    </div>
  )
}

GridCombined.propTypes = {
  objects: PropTypes.array.isRequired,
  learner: PropTypes.object,
  bypassRedux: PropTypes.bool,
  itemClassName: PropTypes.string,
}

export default GridCombined
