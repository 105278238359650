import React from 'react'
import { Routes, Route } from 'react-router-dom'

import { CategoryPage } from '../Category'
import Board from './Board'
import Search from '../Search'
import { CareerCollection } from './CareerCollection'
import ExplorePage from './ExplorePage'
import FaqPage from '../FaqPage'
import TestimonialPage from './TestimonialPage/TestimonialPage'
import { HandoffPage as LearningObjectHandoffPage } from '../LearningObjects'
import usePageTracking from '../../helpers/usePageTracking'
import routes from '../../services/routes'
import HomePage from '../HomePage'

const AppRoutes = function () {
  usePageTracking()

  return (
    <Routes>
      <Route path="/" exact element={<HomePage />} />
      <Route path={routes.explore.index()} element={<ExplorePage />} />
      <Route path={routes.more()} element={<FaqPage />} />
      <Route path={routes.explore.search()} element={<Search />} />
      <Route path={routes.boards.show(':id')} element={<Board />} />
      <Route path={routes.careerCollections.show(':id')} element={<CareerCollection />} />
      <Route
        path={routes.learningObjects.show(':id')}
        element={<LearningObjectHandoffPage />}
      />
      <Route path={routes.categories.show(':id')} element={<CategoryPage />} />
      <Route path={routes.testimonials.index()} element={<TestimonialPage />} />
    </Routes>
  )
}

export default AppRoutes
