import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { startCase } from 'lodash'

import { CategoryActions, LearningObjectsActions } from '../../actions'
import { GridCollections, LoadingGrid } from '../GridComponents'
import {
  usePageTitle,
  LOADING_STATUSES,
  LEARNING_OBJECTS_FIRST_PAGE,
} from '../shared'

const CategoryPage = function () {
  const dispatch = useDispatch()
  const { id } = useParams()
  const category = useSelector((state) => state.local.category.item)
  const categoryStatus = useSelector((state) => state.local.category.status)
  const learner = useSelector((state) => state.app.learner)
  const learningObjectsState = useSelector((state) => state.local.learningObjects)

  useEffect(() => {
    dispatch(CategoryActions.load(id))
    dispatch(LearningObjectsActions.reset())
    dispatch(LearningObjectsActions.load(id, LEARNING_OBJECTS_FIRST_PAGE))
  }, [])

  usePageTitle(`Category: ${startCase(category.title)}`)

  if (categoryStatus !== LOADING_STATUSES.loaded) {
    return null
  }

  return (
    <div className="category__container">
      <article className="category">
        <div className={`category__image category__image--${category.theme_variant}`}>
          <div className="category__title-container">
            <div className="category__title">
              <h1 className="heading-1 heading-1--white heading-1--grows-on-desktop">
                { category.long_title }
              </h1>
            </div>
          </div>
        </div>
        <div className="category__grid-container">
          <div className="content__grid">
            <GridCollections
              collections={category.collections}
              learner={learner}
            />
          </div>
        </div>
        <div className="category__grid-container">
          <div className="content__grid">
            <LoadingGrid
              objectsState={learningObjectsState}
              objects={learningObjectsState.items}
              load={(page) => LearningObjectsActions.load(id, page)}
              incrementPage={LearningObjectsActions.incrementPage}
              firstPage={LEARNING_OBJECTS_FIRST_PAGE}
            />
          </div>
        </div>
      </article>
    </div>
  )
}

export default CategoryPage
