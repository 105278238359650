import {
  take, put, call,
} from 'redux-saga/effects'

import { FeaturedLibrariesActions, FeaturedLibrariesActionTypes } from '../../../actions'
import api from '../../../services/api'

export default function* loadFeaturedLibraries() {
  while (true) {
    yield take(FeaturedLibrariesActionTypes.LOAD)

    try {
      const response = yield call(
        api.homepage.featuredLibraries.show,
      )

      yield put(FeaturedLibrariesActions.loadSuccess(response.data))
    } catch (error) {
      yield put(FeaturedLibrariesActions.loadError(error.response.data.errors))
    }
  }
}
