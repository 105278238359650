import { LearningObjectActionTypes } from '../../../../actions'

export const defaultState = {
  item: {},
}

export default function learningObject(state = defaultState, action) {
  switch (action.type) {
    case LearningObjectActionTypes.LOAD_SUCCESS:
      return { ...state, item: action.learningObject }
    case LearningObjectActionTypes.SET_COLOR:
      return { ...state, color: action.color }
    default:
      return state
  }
}
