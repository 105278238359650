import React from 'react'
import PropTypes from 'prop-types';

import CollectionLearningObjectCard from './CollectionLearningObjectCard'

const CareerCollectionSection = function ({
  headline, subhead, completionRequirements, learningObjects,
}) {
  return (
    <div className="career-collection__collection-section-container">
      <h2 className="heading-2">
        {headline}
      </h2>
      <p className="body-copy-2">
        {subhead}
      </p>
      <div className="career-collection__completion-requirements">
        {completionRequirements}
      </div>
      <div>
        {learningObjects.map((learningObject, index) => (
          <CollectionLearningObjectCard
            key={learningObject.id}
            learningObject={learningObject}
            index={index}
            lastInSection={learningObjects.length - 1 === index}
          />
        ))}
      </div>
    </div>
  )
}

CareerCollectionSection.propTypes = {
  headline: PropTypes.string,
  subhead: PropTypes.string,
  completionRequirements: PropTypes.string,
  learningObjects: PropTypes.array,
};

export default CareerCollectionSection
