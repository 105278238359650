import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import SkipToContentImg from 'images/navbar/nav_bar_bypass.png'
import LogoImg from 'images/logo.png'
import ExploreImg from 'images/navbar/explore.svg'
import MyStuffImg from 'images/navbar/my_stuff.svg'
import EngageImg from 'images/navbar/engage.svg'
import FaqImg from 'images/navbar/faq.svg'
import LoginImg from 'images/navbar/login.svg'
import MyAccountImg from 'images/navbar/my_account.svg'
import routes from '../../services/routes'
import NavButton, { NavLink } from './NavButton'
import { INSTANCE_LOGO_URL, useLoggedIn } from '../shared'

const Nav = function ({
  displayNotification,
  signedIn,
  showEngage,
  outsideApp,
}) {
  let loggedIn = signedIn
  let myDisplayNotification = displayNotification
  let myShowEngage = showEngage
  if (!outsideApp) {
    loggedIn = useLoggedIn()
    myDisplayNotification = useSelector((state) => state.app.env.display_notification)
    myShowEngage = useSelector((state) => state.app.env.engage_section)
  }
  const loc = useLocation()

  return (
    <div className="navbar">
      {loc.pathname === '/'
        && (
        <a href="#featured-content" className="skip-to-featured-content-link">
          <img
            className="skip-to-featured-content-image"
            src={SkipToContentImg}
            alt="Skip to main content"
          />
        </a>
        )}

      <div className="navbar__bar">
        <nav className="navbar__content">
          <NavLink withRouter={!outsideApp} route={routes.root()} classNames="navbar__logo">
            <picture className="navbar__logo-image">
              <source
                media="(min-width: 768px)"
                srcSet={INSTANCE_LOGO_URL}
              />
              <img
                className="navbar__logo-image"
                src={LogoImg}
                alt="Bendable home"
              />
            </picture>
          </NavLink>
          <div className="navbar__spacer" />
          <NavButton
            route={routes.explore.index()}
            title="Discover"
            iconImg={ExploreImg}
            hideSpacer
            withRouter={!outsideApp}
          />
          <NavButton
            route={routes.myStuff.index()}
            title="My stuff"
            iconImg={MyStuffImg}
            activityMonitor
            displayNotification={myDisplayNotification}
          />
          {
            myShowEngage ? (
              <NavButton
                route={routes.more()}
                title="Engage"
                iconClassName="navbar__icon--engage"
                iconImg={EngageImg}
              />
            ) : (
              <NavButton
                route={routes.more()}
                title="FAQs"
                iconClassName="navbar__icon--faq"
                iconImg={FaqImg}
              />
            )
          }
          {
            loggedIn ? (
              <NavButton
                route={routes.myAccount.edit()}
                title="My account"
                iconClassName="navbar__icon--my-account"
                iconImg={MyAccountImg}
              />
            ) : (
              <NavButton
                route={routes.sessions.new()}
                title="Log in"
                iconClassName="navbar__icon--login"
                iconImg={LoginImg}
              />
            )
          }
        </nav>
      </div>
    </div>
  )
}

Nav.propTypes = {
  displayNotification: PropTypes.bool,
  signedIn: PropTypes.bool,
  outsideApp: PropTypes.bool,
  showEngage: PropTypes.bool,
}

export default Nav
