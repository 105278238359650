const LOAD = 'app/providers/LOAD'
const LOAD_SUCCESS = 'app/providers/LOAD_SUCCESS'
const LOAD_ERROR = 'app/providers/LOAD_ERROR'

const load = (id) => ({ type: LOAD, id })
const loadSuccess = (providers) => ({ type: LOAD_SUCCESS, providers })
const loadError = (errors) => ({ type: LOAD_ERROR, errors })

export const ProvidersActions = {
  load,
  loadSuccess,
  loadError,
}

export const ProvidersActionTypes = {
  LOAD,
  LOAD_SUCCESS,
  LOAD_ERROR,
}
